import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Result, useFetch } from "../clients/useFetch";
import { CreateUser, useCreateUser, User, useUser } from "../clients/userClient";
import { NotificationContext } from "./NotificationContext";
import { Role } from "../clients/roleClient";

interface UserType {
  users: User[];
  refetchUsers: () => void;
  updateUser: (user: User) => void;
  updateUserRoles: (userId: string, userRoles: Role[]) => void;
  handleCreateUser: (user: CreateUser) => void;
  isConfirmButtonLoading: boolean;
  userResult: Result<User>;
  resetDialog: () => void;
  setCreateUserDialogActive: (createUserDialogActive: boolean) => void;
  createUserDialogActive: boolean;
}

export const UserContext = createContext({} as UserType);

const UserProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);
  const [createUserDialogActive, setCreateUserDialogActive] = useState(false);
  const { fetcher, result } = useFetch<User[]>();
  const { createUser, createUserResult } = useCreateUser();
  const { updateUser, updateUserRoles, userResult } = useUser();
  const { setNotification } = useContext(NotificationContext);
  const intl = useIntl();

  const refetchUsers = () => {
    getUsers();
  };

  const getUsers = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + "/user");
  }, [fetcher]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleCreateUser = useCallback(
    (user: CreateUser) => {
      setIsConfirmButtonLoading(true);
      user.accountInfo.username = `${user.firstName} ${user.lastName}`;
      createUser(user);
    },
    [createUser],
  );

  const resetDialog = () => {
    setIsConfirmButtonLoading(false);
    setCreateUserDialogActive(false);
  };

  // Fetch
  useEffect(() => {
    if (result.status === "loaded") {
      setUsers(result.payload);
    }
    if (result.status === "error") {
      if (result.responseErrorCode === 403) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.permission" }) });
      } else {
        setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
      }
    }
  }, [result, setNotification, intl]);

  // Create
  useEffect(() => {
    if (createUserResult.status === "loaded") {
      setUsers([...users, createUserResult.payload]);
      resetDialog();
      setNotification({ type: "success", message: intl.formatMessage({ id: "UserContext.CreateUser.message" }) });
    }

    if (createUserResult.status === "error") {
      if (createUserResult.responseErrorCode === 403) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "UserContext.noPermission.message" }) });
      }
      if (createUserResult.responseErrorCode === 404) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "UserContext.UserNotFound.message" }) });
      }
      if (createUserResult.responseErrorCode === 409) {
        setIsConfirmButtonLoading(false);
        setNotification({ type: "error", message: intl.formatMessage({ id: "UserContext.UserExists.message" }) });
      }
    }
    // eslint-disable-next-line
  }, [createUserResult, setNotification, intl]);

  // Update
  useEffect(() => {
    if (userResult.status === "loaded") {
      if (typeof userResult.payload === "object") {
        setNotification({ type: "success", message: intl.formatMessage({ id: "UserContext.UpdateUser.message" }) });
      }
    }

    if (userResult.status === "error") {
      if (userResult.responseErrorCode === 404) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "UserContext.UserNotFound.message" }) });
      } else {
        setNotification({ type: "error", message: intl.formatMessage({ id: "UserContext.CouldNotUpdateUser.message" }) });
      }
    }
  }, [userResult, setNotification, intl]);

  const data = {
    users,
    refetchUsers,
    updateUser,
    updateUserRoles,
    handleCreateUser,
    isConfirmButtonLoading,
    userResult,
    resetDialog,
    setCreateUserDialogActive,
    createUserDialogActive,
  };

  return <UserContext.Provider value={data}> {children} </UserContext.Provider>;
};

export default UserProvider;
