import React, { FC, useState, useContext } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Chip,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Edit, Inbox } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { grey } from "@mui/material/colors";
import EditContactPersonsModal from "./EditContactPersonsModal";
import { ProjectContext } from "../../contexts/ProjectContext";

const ProjectInformationTabPanel: FC = () => {
  const { currentProject } = useContext(ProjectContext);
  const { contactPersons } = currentProject;
  const [newContactPersonDialog, setNewContactPersonDialog] = useState<boolean>(false);

  const separateThousands = (num: number): string => num.toLocaleString("de-DE");

  return (
    <Container>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <Grid item md={6}>
          <TextField
            data-testid="CustomerTextfield"
            variant="filled"
            fullWidth
            disabled
            inputProps={{ readOnly: true }}
            value={currentProject.customer ?? ""}
            label={<FormattedMessage id="ProjectDetailScreen.tab.projectInformation.customer" />}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            data-testid="BudgetTextfield"
            variant="filled"
            fullWidth
            disabled
            inputProps={{ readOnly: true }}
            value={separateThousands(currentProject.budget)}
            label={<FormattedMessage id="ProjectDetailScreen.tab.projectInformation.budget" />}
            InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
          />
        </Grid>
        <Grid item md={3}>
          <TextField
            data-testid="ProjectStartTextfield"
            variant="filled"
            fullWidth
            disabled
            inputProps={{ readOnly: true }}
            value={new Date(currentProject.projectStart).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
            label={<FormattedMessage id="ProjectDetailScreen.tab.projectInformation.projectStart" />}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle1" sx={{ mt: 4 }}>
        <FormattedMessage id="ProjectDetailScreen.tab.projectInformation.contactPerson" />
      </Typography>
      <TableContainer component={Card} sx={{ mt: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: grey[300] }} colSpan={2}>
                <Typography variant="h5">
                  <FormattedMessage id="ProjectDetailScreen.tab.projectInformation.table.name" />
                </Typography>
              </TableCell>
              <TableCell sx={{ backgroundColor: grey[300] }}>
                <Typography variant="h5">
                  <FormattedMessage id="ProjectDetailScreen.tab.projectInformation.table.role" />
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ backgroundColor: grey[300], py: 0, maxWidth: "145px" }}>
                <Button
                  data-testid="EditContactPersonsButton"
                  onClick={() => setNewContactPersonDialog(true)}
                  startIcon={<Edit />}
                  variant="contained">
                  <FormattedMessage id="ProjectDetailScreen.tab.projectInformation.table.editButton" />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="tbody">
            {contactPersons.length > 0 ? (
              contactPersons.map(({ accountInfo }) => (
                <TableRow data-testid={`ContactPersonRow-${accountInfo.username}`} key={accountInfo.username} hover>
                  <TableCell sx={{ py: 0, pr: 0, width: "44px" }}>
                    <Avatar sx={{ width: "44px", height: "44px" }} src={accountInfo.picture} />
                  </TableCell>
                  <TableCell width="200px" sx={{ py: "10px" }}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h5">{accountInfo.username}</Typography>
                      <Typography variant="body2">{accountInfo.email}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {accountInfo.roles.map((role) => (
                        <Chip size="small" color="primary" key={role.id} label={role.name} />
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow data-testid="EmptyStateRow">
                <TableCell align="center" colSpan={8}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Inbox color="action" sx={{ mb: 2, width: 128, height: 128, p: 3, backgroundColor: "#e5e5e5", borderRadius: "50%" }} />
                    <Typography color="action" variant="subtitle1">
                      <FormattedMessage id="ProjectDetailScreen.tab.projectInformation.table.emptyState" />
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EditContactPersonsModal openDialog={newContactPersonDialog} setOpenDialog={setNewContactPersonDialog} />
    </Container>
  );
};

export default ProjectInformationTabPanel;
