export enum PrivilegeEnum {
  CAN_CREATE_PROJECT = "CAN_CREATE_PROJECT",
  CAN_READ_PROJECT = "CAN_READ_PROJECT",
  CAN_EDIT_PROJECT = "CAN_EDIT_PROJECT",
  CAN_DELETE_PROJECT = "CAN_DELETE_PROJECT",
  CAN_READ_KEYINFORMATION = "CAN_READ_KEYINFORMATION",
  CAN_EDIT_KEYINFORMATION = "CAN_EDIT_KEYINFORMATION",
  CAN_CREATE_USER = "CAN_CREATE_USER",
  CAN_READ_USER = "CAN_READ_USER",
  CAN_EDIT_USER = "CAN_EDIT_USER",
  CAN_DELETE_USER = "CAN_DELETE_USER",
  CAN_CREATE_ROLE = "CAN_CREATE_ROLE",
  CAN_READ_ROLE = "CAN_READ_ROLE",
  CAN_EDIT_ROLE = "CAN_EDIT_ROLE",
  CAN_DELETE_ROLE = "CAN_DELETE_ROLE",
  CAN_CREATE_SALARY = "CAN_CREATE_SALARY",
  CAN_READ_SALARY = "CAN_READ_SALARY",
  CAN_EDIT_SALARY = "CAN_EDIT_SALARY",
  CAN_DELETE_SALARY = "CAN_DELETE_SALARY",
}
