import { Add, Send } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorMessage from "../ErrorMessage";
import { useRole, useAllRoles, useAllPrivileges } from "../../clients/roleClient";
import { LoadingButton } from "@mui/lab";
import RolesScreenRow from "./RolesScreenRow";
import { RolesContext } from "../../contexts/RoleContext";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "./PrivilegeEnum";

const RolesScreen: FC = () => {
  const { allRolesResult, refreshAllRoles } = useAllRoles();
  const { allPrivilegesResult } = useAllPrivileges();
  const { roles, createRole } = useContext(RolesContext);
  const { checkForPrivilege } = useContext(PrivilegeContext);
  const { roleResult: roleCreateResult } = useRole();
  const [createFormActive, setCreateFormActive] = useState<boolean>(false);
  const [createRoleName, setCreateRoleName] = useState("");
  const intl = useIntl();

  const handleAbort = () => {
    setCreateFormActive(false);
    setCreateRoleName("");
  };

  return (
    <Container sx={{ marginY: 2 }} maxWidth="md">
      {roles.length > 0 ? (
        <TableContainer elevation={3} sx={{ p: 2, mb: 4 }} component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="RolesScreen.table.rolename" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="RolesScreen.table.editPrivileges" />
                </TableCell>
                <TableCell align="right">
                  {checkForPrivilege(PrivilegeEnum.CAN_CREATE_ROLE) ? (
                    <Button
                      role={"Button"}
                      aria-label="setCreateFormBtn"
                      disabled={createFormActive}
                      onClick={() => {
                        setCreateFormActive(true);
                      }}
                      color="primary"
                      variant="contained"
                      startIcon={<Add />}>
                      <FormattedMessage id="RolesScreen.createButton" />
                    </Button>
                  ) : null}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {createFormActive ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <TextField
                      role="TextField"
                      aria-label="createNewRoleTextField"
                      error={roleCreateResult.status === "error"}
                      required
                      fullWidth
                      autoFocus
                      onChange={(e) => setCreateRoleName(e.target.value)}
                      id="rolename"
                      variant="standard"
                      label={intl.formatMessage({ id: "RolesScreen.table.rolename" })}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button color="error" sx={{ mr: 1 }} onClick={handleAbort}>
                      Abbrechen
                    </Button>
                    <LoadingButton
                      role="LoadingButton"
                      aria-label="createRoleBtn"
                      disabled={createRoleName.length < 1}
                      onClick={() => {
                        createRole(createRoleName);
                        setCreateFormActive(false);
                        setCreateRoleName("");
                      }}
                      endIcon={<Send />}
                      loading={roleCreateResult.status === "loading"}
                      loadingPosition="end"
                      variant="contained">
                      <FormattedMessage id="RolesScreen.create" />
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ) : null}
              {roles.map((role) => (
                <RolesScreenRow
                  key={role.id}
                  role={role}
                  refreshAllRoles={refreshAllRoles}
                  privileges={allPrivilegesResult.status === "loaded" ? allPrivilegesResult.payload : []}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : allRolesResult.status === "error" ? (
        <ErrorMessage messageId={allRolesResult.messageId} />
      ) : (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </Container>
  );
};

export default RolesScreen;
