import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Salary, useCreateSalary, useDeleteSalary, useUpdateSalary } from "../clients/salaryClient";
import { useFetch } from "../clients/useFetch";
import { NotificationContext } from "./NotificationContext";

interface SalaryType {
  salaries: Salary[];
  refetchSalaries: () => void;
  setSalaries: Dispatch<SetStateAction<Salary[]>>;
  createSalary: (userId: string, salaries: Salary) => void;
  handleSalaryUpdate: (salaries: Salary) => void;
  submitSalaryDelete: (id: string) => void;
}

export const SalaryContext = createContext({} as SalaryType);

const SalaryProvider: React.FC = ({ children }) => {
  const [salaries, setSalaries] = useState<Salary[]>([]);
  const [salaryId, setSalaryId] = useState<string>("");
  const { fetcher, result } = useFetch<Salary[]>();
  const { setNotification } = useContext(NotificationContext);
  const { createSalary, createSalaryResult } = useCreateSalary();
  const { updateSalary, updateSalaryResult } = useUpdateSalary();
  const { deleteSalary, deleteSalaryResult } = useDeleteSalary();
  const { userId } = useParams<{ userId: string }>();
  const intl = useIntl();

  const getSalaries = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + `/salary/${userId}`);
  }, [fetcher, userId]);

  const refetchSalaries = () => {
    getSalaries();
  };

  useEffect(() => {
    getSalaries();
  }, [getSalaries]);

  const handleSalaryUpdate = useCallback(
    (salary: Salary) => {
      updateSalary(salary);
    },
    [updateSalary],
  );

  const submitSalaryDelete = useCallback(
    (salaryId: string) => {
      deleteSalary(salaryId);
      setSalaryId(userId);
    },
    [deleteSalary, userId],
  );

  useEffect(() => {
    if (result.status === "loaded") {
      setSalaries(result.payload);
    }
    if (result.status === "error") {
      if (result.responseErrorCode === 403) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.permission" }) });
      } else {
        setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
      }
    }
  }, [result, setNotification, intl]);

  // Create
  useEffect(() => {
    if (createSalaryResult.status === "loaded") {
      if (typeof createSalaryResult.payload === "object") {
        setSalaries([...salaries, createSalaryResult.payload]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "SalaryContext.CreateSalary.message" }) });
      }
    }

    if (createSalaryResult.status === "error") {
      if (createSalaryResult.responseErrorCode === 403) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.noPermission.message" }) });
      }
      if (createSalaryResult.responseErrorCode === 404) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "SalaryContext.SalaryNotFound.message" }) });
      }
    }

    // eslint-disable-next-line
  }, [createSalaryResult, setNotification, intl]);

  useEffect(() => {
    if (updateSalaryResult.status === "loaded") {
      if (typeof updateSalaryResult.payload === "object") {
        if (updateSalaryResult.responseStatusCode === 200) {
          const payload = updateSalaryResult.payload as Salary;
          const tmpSalaries = salaries.filter((salary) => {
            return salary.id !== payload.id;
          });
          setSalaries([...tmpSalaries, payload]);
          setNotification({ type: "success", message: intl.formatMessage({ id: "SalaryContext.SalaryChanged.message" }) });
        }
      }
    }
    // eslint-disable-next-line
  }, [updateSalaryResult, setNotification, intl]);

  useEffect(() => {
    if (deleteSalaryResult.status === "loadedEmpty") {
      setNotification({ type: "success", message: intl.formatMessage({ id: "SalaryContext.SalaryDelete.message" }) });
      const filtertSalaries = salaries.filter((salary) => {
        return salary.id !== salaryId;
      });
      setSalaryId("");
      setSalaries([...filtertSalaries]);
    }
    // eslint-disable-next-line
  }, [deleteSalaryResult, setNotification, intl]);

  const data = {
    salaries,
    refetchSalaries,
    setSalaries,
    createSalary,
    handleSalaryUpdate,
    submitSalaryDelete,
  };
  return <SalaryContext.Provider value={data}> {children} </SalaryContext.Provider>;
};

export default SalaryProvider;
