import { Avatar, Box, Card, Container, Grid, Typography, CircularProgress, Stack, IconButton } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { EmailOutlined, ArrowBack } from "@mui/icons-material";
import { useParams } from "react-router";
import { useUser } from "../../../clients/userClient";
import ErrorMessage from "../../ErrorMessage";
import EmployeeSalaryInformation from "./EmployeeSalary/EmployeeSalaryInformation";
import EmployeePersonalData from "./EmployeePersonalData";
import EmployeeRoleTable from "./EmployeeRoleTable";
import { useHistory } from "react-router";
import { PrivilegeEnum } from "../../RolesScreen/PrivilegeEnum";
import { PrivilegeContext } from "../../../contexts/PrivilegeContext";

const EmployeeProfileScreen: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();

  const { updateUserRoles, userResult, getUser } = useUser();
  const { checkForPrivilege } = useContext(PrivilegeContext);

  useEffect(() => {
    getUser(userId);
  }, [getUser, userId]);

  return (
    <Container maxWidth="xl" data-testid="profile">
      {userResult.status === "loaded" ? (
        <Box sx={{ display: "flex", my: 4 }}>
          <Box>
            <IconButton color="primary" onClick={() => history.push({ pathname: "/users/" })}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box sx={{ px: 4 }}>
            <Box sx={{ ml: 1 }}>
              <Avatar sx={{ width: 94, height: 94 }} src={userResult.payload.accountInfo.picture} />
            </Box>
            <Typography sx={{ mt: 2 }} variant="h5">
              {userResult.payload.accountInfo.username}
            </Typography>
            <Typography variant="subtitle2">
              <FormattedMessage id="AccountScreen.lastLogin" />: {userResult.payload.accountInfo.lastLogin}
            </Typography>
            <Card sx={{ my: 2, p: 1 }}>
              <Typography sx={{ textTransform: "uppercase" }} variant="overline">
                <FormattedMessage id="AccountScreen.contact" />
              </Typography>
              <Grid container direction="row" alignItems="center">
                <EmailOutlined sx={{ mr: 1 }} />
                {userResult.payload.accountInfo.email}
              </Grid>
            </Card>
            <EmployeeRoleTable user={userResult.payload} updateUserRoles={updateUserRoles} />
          </Box>
          <Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <EmployeePersonalData user={userResult.payload} />
            </Box>
            {checkForPrivilege(PrivilegeEnum.CAN_READ_SALARY) ? <EmployeeSalaryInformation /> : null}
          </Box>
        </Box>
      ) : userResult.status === "loading" ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : userResult.status === "error" ? (
        <ErrorMessage messageId={userResult.messageId} />
      ) : (
        <ErrorMessage messageId="General.error.authentication" />
      )}
    </Container>
  );
};

export default EmployeeProfileScreen;
