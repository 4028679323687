import { FC, useState, useContext } from "react";
import { useParams } from "react-router";
import { ArrowBack, Edit, Key } from "@mui/icons-material";
import { Box, IconButton, Container, Tab, Tabs, Typography, Chip, Tooltip, Button } from "@mui/material";
import ProjectInformationTabPanel from "./ProjectInformationTabPanel";
import EmployeeListTabPanel from "./EmployeeListTabPanel";
import ProjectStateTabPanel from "./ProjectStateTabPanel";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { ProjectContext } from "../../contexts/ProjectContext";
import EditProjectInfoModal from "./EditProjectInfoModal";
import { useProjectUtils } from "../../utils/Utils";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../RolesScreen/PrivilegeEnum";

const ProjectDetailScreen: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { currentProject } = useContext(ProjectContext);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { checkForPrivilege } = useContext(PrivilegeContext);
  const history = useHistory();
  const { getProjectStatusColor, getProjectStatusIcon } = useProjectUtils();
  const [newProjectInfoDialog, setNewProjectInfoDialog] = useState<boolean>(false);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      {currentProject.id === projectId ? (
        <Container maxWidth="md" sx={{ mt: 4, mb: 2 }} style={{ flex: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
            <IconButton
              color="primary"
              onClick={() => {
                history.push({ pathname: "/project" });
              }}>
              <ArrowBack />
            </IconButton>
            <Typography component={"span"} variant="h6">
              {currentProject.title}
            </Typography>
            <Chip
              sx={{ borderRadius: 1 }}
              variant="outlined"
              color="primary"
              size="small"
              label={<FormattedMessage id={`ProjectDetailScreen.${currentProject.external ? "external" : "internal"}`} />}
            />
            <Tooltip
              title={
                <FormattedMessage
                  id={`ProjectDetailScreen.tab.projectStatus.${currentProject.status ? currentProject.status.toLowerCase() : "unknown"}`}
                />
              }>
              <Chip
                size="small"
                sx={{ borderRadius: 1, display: "grid", placeItems: "center" }}
                color={getProjectStatusColor()}
                label={getProjectStatusIcon()}
              />
            </Tooltip>
            <Box style={{ marginLeft: "auto" }}>
              {checkForPrivilege(PrivilegeEnum.CAN_READ_KEYINFORMATION) ? (
                <Button
                  size="small"
                  style={{ marginRight: 6 }}
                  onClick={() => history.push({ pathname: `/projectkeyinformation/${projectId}/keyinformation/` })}
                  startIcon={<Key />}
                  variant="contained">
                  <FormattedMessage id="ProjectKeyInformation.Table.title" />
                </Button>
              ) : null}
              <Button size="small" onClick={() => setNewProjectInfoDialog(true)} startIcon={<Edit />} variant="contained">
                <FormattedMessage id="ProjectDetailScreen.editButton" />
              </Button>
            </Box>
          </Box>
          <Typography component={"span"} variant="body2" color="GrayText">
            {currentProject.description ?? <FormattedMessage id="ProjectDetailScreen.tab.projectInformation.description.emptyState" />}
          </Typography>
          <Box sx={{ mt: 4, borderBottom: 1, borderColor: "divider" }}>
            <Tabs onChange={(e, nextTab) => setSelectedTab(nextTab)} value={selectedTab} indicatorColor="primary">
              <Tab
                label={<FormattedMessage id="ProjectDetailScreen.tab.projectInformation.title" />}
                id="simple-tab-0"
                aria-controls="simple-tabpanel-0"
              />
              <Tab
                label={<FormattedMessage id="ProjectDetailScreen.tab.employeeList.title" />}
                id="simple-tab-1"
                aria-controls="simple-tabpanel-1"
              />
              <Tab
                label={<FormattedMessage id="ProjectDetailScreen.tab.projectStatus.title" />}
                id="simple-tab-2"
                aria-controls="simple-tabpanel-2"
              />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0} data-testid="tabPanel-0">
            <ProjectInformationTabPanel />
          </TabPanel>
          <TabPanel value={selectedTab} index={1} data-testid="tabPanel-1">
            <EmployeeListTabPanel />
          </TabPanel>
          <TabPanel value={selectedTab} index={2} data-testid="tabPanel-2">
            <ProjectStateTabPanel />
          </TabPanel>
          <EditProjectInfoModal openDialog={newProjectInfoDialog} setOpenDialog={setNewProjectInfoDialog} />
        </Container>
      ) : null}
      ;
    </>
  );
};

export default ProjectDetailScreen;
