import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Privilege } from "../../clients/roleClient";

const EditPrivileges: FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (newPrivileges: Privilege[]) => void;
  allPrivileges: Privilege[];
  rolePrivileges?: Privilege[];
}> = ({ open, onClose, onSubmit, allPrivileges, rolePrivileges }): ReactElement => {
  const [privilegeArray, setPrivilegeArray] = useState<Privilege[]>([]);

  useEffect(() => {
    if (rolePrivileges) setPrivilegeArray(rolePrivileges);
  }, [rolePrivileges]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="RolesScreen.editPrivileges" />
      </DialogTitle>
      <DialogContent>
        <Card sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <CardContent>
            {allPrivileges.map((privilege) => (
              <Box key={privilege.privilegeKey}>
                <FormControlLabel
                  sx={{ alignItems: "center" }}
                  control={
                    <Checkbox
                      name={privilege.privilegeKey.toString()}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const checkedPrivilege = allPrivileges.find((element) => element.privilegeKey.toString() === event.target.name);
                        if (event.target.checked) {
                          if (checkedPrivilege) {
                            setPrivilegeArray((prevPrivilegeArray) => [...prevPrivilegeArray, checkedPrivilege]);
                          }
                        } else {
                          setPrivilegeArray((prevPrivilegeArray) =>
                            prevPrivilegeArray.filter((element) => element.privilegeKey.toString() !== event.target.name),
                          );
                        }
                      }}
                      checked={
                        privilegeArray.find((element) => element.privilegeKey.toString() === privilege.privilegeKey.toString())
                          ? true
                          : false
                      }
                    />
                  }
                  label={
                    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                      <Box>
                        <Typography color="primary">{privilege.value}</Typography>
                        <Typography>{privilege.description}</Typography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            ))}
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          <FormattedMessage id="RolesScreen.deleteCancel" />
        </Button>
        <Button
          aria-label="save-privileges"
          color="primary"
          variant="contained"
          sx={{ display: "flex", gap: 1 }}
          onClick={() => {
            onSubmit(privilegeArray);
            onClose();
          }}>
          <Save />
          <FormattedMessage id="RolesScreen.savePrivileges" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPrivileges;
