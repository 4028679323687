import React, { useCallback, useContext, useEffect, useState } from "react";
import { createContext, Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import { Role, useRole } from "../clients/roleClient";
import { useFetch } from "../clients/useFetch";
import { NotificationContext } from "./NotificationContext";

interface RoleType {
  roles: Role[];
  refetchRoles: () => void;
  setRoles: Dispatch<SetStateAction<Role[]>>;
  createRole: (roles: string) => void;
  submitRoleDelete: (id: string) => void;
  handleRoleUpdate: (role: Role) => void;
}

export const RolesContext = createContext({} as RoleType);

const RoleProvider: React.FC = ({ children }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [id, setId] = useState<string>("");
  const { fetcher, result } = useFetch<Role[]>();
  const { createRole, roleResult, deleteRole, updateRole } = useRole();
  const intl = useIntl();

  const { setNotification } = useContext(NotificationContext);

  const getRoles = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + "/role");
  }, [fetcher]);

  const refetchRoles = () => {
    getRoles();
  };

  const submitRoleDelete = useCallback(
    (roleId: string) => {
      deleteRole(roleId);
      setId(roleId);
    },
    [deleteRole],
  );

  const handleRoleUpdate = useCallback(
    (role: Role) => {
      updateRole(role);
    },
    [updateRole],
  );

  // Fetching
  useEffect(() => {
    getRoles();
  }, [getRoles]);

  // Fetchresult
  useEffect(() => {
    if (result.status === "loaded") {
      setRoles(result.payload);
    }
    if (result.status === "error") {
      if (result.responseErrorCode === 403) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.permission" }) });
      } else {
        setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
      }
    }
  }, [result, setNotification, intl]);

  useEffect(() => {
    if (roleResult.status === "loaded") {
      if (roleResult.payload === "NO_CONTENT") {
        setNotification({ type: "success", message: intl.formatMessage({ id: "RoleContext.RoleDelete.message" }) });
        const filteredRoles = roles.filter((role) => {
          return role.id !== id;
        });
        setId("");
        setRoles([...filteredRoles]);
      } else if (typeof roleResult.payload === "object") {
        if (roleResult.responseStatusCode === 200) {
          const payload = roleResult.payload as Role;
          const tmpRoles = roles.filter((role) => {
            return role.id !== payload.id;
          });
          setRoles([...tmpRoles, payload]);
          setNotification({ type: "success", message: intl.formatMessage({ id: "RoleContext.RoleChanged.message" }) });
        } else {
          setRoles([...roles, roleResult.payload]);
          setNotification({ type: "success", message: intl.formatMessage({ id: "RoleContext.CreateRole.message" }) });
        }
      }
    }

    if (roleResult.status === "error") {
      if (roleResult.responseErrorCode === 403) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "RoleContext.noPermission.message" }) });
      }
      if (roleResult.responseErrorCode === 404) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "RoleContext.RoleNotFound.message" }) });
      }
      if (roleResult.responseErrorCode === 409) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "RoleContext.RoleOccupied.message" }) });
      }
    }
    // eslint-disable-next-line
  }, [roleResult, setNotification, intl]);

  const data = {
    roles,
    refetchRoles,
    setRoles,
    createRole,
    submitRoleDelete,
    handleRoleUpdate,
  };

  return <RolesContext.Provider value={data}> {children} </RolesContext.Provider>;
};

export default RoleProvider;
