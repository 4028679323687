import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

interface ErrorMessageProps {
  messageId?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ messageId = "General.error.default" }) => {
  let errorId = "General.error.default";
  if (messageId) {
    errorId = messageId;
  }
  return (
    <Box sx={{ mt: 3 }} style={{ textAlign: "center" }}>
      <Typography variant="h4" color="error">
        <FormattedMessage id={errorId} />
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
