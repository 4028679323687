import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import {
  CreateProjectDTO,
  Project,
  useCreateProject,
  useUpdateProject,
  useDeleteProject,
  useProject,
  UpdateProjectDTO,
  ProjectEmployee,
  useAllProjects,
  Keyinformation,
  useProjectKeyinformation,
  useUpdateProjectKeyinformation,
} from "../clients/projectClient";
import { User } from "../clients/userClient";
import { NotificationContext } from "./NotificationContext";

interface ProjectType {
  projects: Project[];
  refreshProject: () => void;
  setProjects: Dispatch<SetStateAction<Project[]>>;
  createProject: (project: CreateProjectDTO) => void;
  submitProjectDelete: (id: string) => void;
  keyinformation?: Keyinformation;
  updateKeyinformation: (keyinformation: Keyinformation) => void;
  updateProject: (project: UpdateProjectDTO) => void;
  currentProject: Project;
  updateContactPersons: (selectedContactPersons: User[]) => void;
  updateProjectEmployees: (selectedProjectEmployees: ProjectEmployee[]) => void;
  setCurrentProject: (project: Project) => void;
}

export const ProjectContext = createContext({} as ProjectType);

const ProjectProvider: React.FC = ({ children }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { createProject, createProjectResult } = useCreateProject();
  const { deleteProject, deleteProjectResult } = useDeleteProject();
  const { setNotification } = useContext(NotificationContext);
  const { projectsResult } = useAllProjects();
  const { projectResult, refreshProject } = useProject(projectId ?? "");
  const { updateProject, updateProjectResult } = useUpdateProject(projectId ?? "");
  const { keyinformationResult } = useProjectKeyinformation(projectId ?? "");
  const { updateProjectKeyinformationResult, updateProjectKeyinformation } = useUpdateProjectKeyinformation(projectId ?? "");
  const [projects, setProjects] = useState<Project[]>([]);
  const [keyinformation, setKeyinformation] = useState<Keyinformation>();
  const [deletedProjectId, setDeleteProjectId] = useState<string>("");
  const [currentProject, setCurrentProject] = useState<Project>({
    id: "",
    title: "",
    customer: "",
    projectStart: "",
    budget: 0,
    status: "",
    statusDetails: "",
    contactPersons: [],
    projectEmployees: [],
    external: false,
  });

  const intl = useIntl();

  // Update Project Keyinformations
  const updateKeyinformation = useCallback(
    (keyinformation: Keyinformation) => {
      updateProjectKeyinformation(keyinformation);
    },
    [updateProjectKeyinformation],
  );

  const submitProjectDelete = useCallback(
    (projectId: string) => {
      setDeleteProjectId(projectId);
      deleteProject(projectId);
    },
    [deleteProject],
  );

  // Update Contact Persons
  const updateContactPersons = (selectedContactPersons: User[]) => {
    const updatedProject: UpdateProjectDTO = {
      ...currentProject,
      // UpdateProjectDTO only needs Ids of contactPersons:
      contactPersons: selectedContactPersons.map((contactPerson) => contactPerson.id),
    };

    updateProject(updatedProject);
  };

  // Update Project Employees
  const updateProjectEmployees = (selectedProjectEmployees: ProjectEmployee[]) => {
    const updatedProject: UpdateProjectDTO = {
      ...currentProject,
      // UpdateProjectDTO only needs Ids of contactPersons:
      contactPersons: currentProject.contactPersons.map((contactPerson) => contactPerson.id),
      projectEmployees: selectedProjectEmployees,
    };

    updateProject(updatedProject);
  };

  useEffect(() => {
    if (updateProjectKeyinformationResult.status === "loaded") {
      setKeyinformation(updateProjectKeyinformationResult.payload);
      setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.Update.message" }) });
    }
    if (updateProjectKeyinformationResult.status === "error") {
      switch (updateProjectKeyinformationResult.responseErrorCode) {
        case 404:
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
          break;
        case 403:
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
          break;
        default:
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
          break;
      }
    }
  }, [updateProjectKeyinformationResult, setNotification, intl]);

  // Get Keyinformation
  useEffect(() => {
    if (keyinformationResult.status === "loaded") {
      setKeyinformation(keyinformationResult.payload);
    }
    if (keyinformationResult.status === "error") {
      switch (keyinformationResult.responseErrorCode) {
        case 404:
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.NotFound.message" }) });
          break;
        case 403:
          setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.Keyinformation.noPermission.message" }) });
          break;
        default:
          setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
          break;
      }
    }
  }, [keyinformationResult, setNotification, intl]);

  // Get Projects
  useEffect(() => {
    if (projectsResult.status === "loaded") {
      setProjects(projectsResult.payload);
    }
    if (projectsResult.status === "error") {
      setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
    }
  }, [projectsResult, setNotification, intl]);

  // Get Project
  useEffect(() => {
    if (projectResult.status === "loaded") {
      if (typeof projectResult.payload === "object") {
        setCurrentProject(projectResult.payload);
      }
    }

    // Create
    if (projectResult.status === "error") {
      if (projectResult.responseErrorCode === 403)
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.noPermission.message" }) });
      if (projectResult.responseErrorCode === 404) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.ProjectNotFound.message" }) });
      }
    }
  }, [projectResult, setNotification, intl]);

  // Update
  useEffect(() => {
    if (updateProjectResult.status === "loaded") {
      if (typeof updateProjectResult.payload === "object") {
        setCurrentProject(updateProjectResult.payload);
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.UpdateProject.message" }) });
      }
    }

    if (updateProjectResult.status === "error") {
      if (updateProjectResult.responseErrorCode === 403)
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.noPermission.message" }) });
      if (updateProjectResult.responseErrorCode === 404)
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.ProjectNotFound.message" }) });
      else setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.UpdateProjectError.message" }) });
    }
  }, [updateProjectResult, setNotification, intl]);

  // Create Project
  useEffect(() => {
    if (createProjectResult.status === "loaded") {
      if (typeof createProjectResult.payload === "object") {
        setProjects([...projects, createProjectResult.payload]);
        setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.CreateProject.message" }) });
      }
    }

    if (createProjectResult.status === "error") {
      if (createProjectResult.responseErrorCode === 404) {
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.ProjectNotFound.message" }) });
      }
      if (createProjectResult.responseErrorCode === 409)
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.ProjectExists.message" }) });
      else setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
    }
    // eslint-disable-next-line
  }, [createProjectResult, setNotification, intl]);

  // Delete
  useEffect(() => {
    if (deleteProjectResult.status === "loadedEmpty") {
      setNotification({ type: "success", message: intl.formatMessage({ id: "ProjectContext.ProjectDelete.message" }) });
      const filteredProjects = projects.filter((project) => {
        return project.id !== deletedProjectId;
      });
      setDeleteProjectId("");
      setProjects([...filteredProjects]);
    }
    if (deleteProjectResult.status === "error") {
      if (deleteProjectResult.responseErrorCode === 403)
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.noPermission.message" }) });
      if (deleteProjectResult.responseErrorCode === 404)
        setNotification({ type: "error", message: intl.formatMessage({ id: "ProjectContext.ProjectNotFound.message" }) });
      else setNotification({ type: "error", message: intl.formatMessage({ id: "General.error.server" }) });
    }
    // eslint-disable-next-line
  }, [deleteProjectResult, setNotification, intl]);

  const data = {
    projects,
    refreshProject,
    setProjects,
    createProject,
    submitProjectDelete,
    keyinformation,
    updateKeyinformation,
    updateProject,
    currentProject,
    updateContactPersons,
    updateProjectEmployees,
    setCurrentProject,
  };

  return <ProjectContext.Provider value={data}>{children}</ProjectContext.Provider>;
};

export default ProjectProvider;
