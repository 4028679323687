import { ThemeProvider } from "@mui/material";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import App from "./App";
import messages_de from "./assets/i18/de.json";
import "./index.css";
import theme from "./assets/theming/theme";
import CssBaseline from "@mui/material/CssBaseline";

const language = navigator.language.split(/[-_]/)[0]; // language without region code

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={language} messages={messages_de}>
          <CssBaseline />
          <App />
        </IntlProvider>
      </ThemeProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
