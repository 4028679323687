import { Add, Delete } from "@mui/icons-material";
import { Button, IconButton, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from "@mui/material";
import { FC, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Project } from "../../clients/projectClient";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../RolesScreen/PrivilegeEnum";
import ProjectCreateDialog from "./ProjectCreateDialog";
import ProjectDeleteDialog from "./ProjectDeleteDialog";
import { grey } from "@mui/material/colors";

interface ProjectTableProps {
  projects: Project[];
  external?: boolean;
}

const ProjectTable: FC<ProjectTableProps> = ({ projects, external }: ProjectTableProps) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<Project>();
  const { checkForPrivilege } = useContext(PrivilegeContext);

  const history = useHistory();

  if (external) {
    projects = projects.filter((project) => {
      return project.external === true;
    });
  } else {
    projects = projects.filter((project) => {
      return project.external === false;
    });
  }

  return (
    <>
      <TableContainer component={Card}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: grey[300] }}>
                <FormattedMessage id="ProjectScreen.Table.TableHeader.Name" />
              </TableCell>
              <TableCell sx={{ backgroundColor: grey[300] }}>
                <FormattedMessage id="ProjectScreen.Table.TableHeader.CustomerName" />
              </TableCell>
              <TableCell sx={{ backgroundColor: grey[300] }}>
                <FormattedMessage id="ProjectScreen.Table.TableHeader.Status" />
              </TableCell>
              <TableCell sx={{ backgroundColor: grey[300], py: 0 }} align="right">
                {checkForPrivilege(PrivilegeEnum.CAN_CREATE_PROJECT) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenCreateDialog(true)}
                    startIcon={<Add />}
                    data-testid="CreateProjectButton">
                    <FormattedMessage id="ProjectScreen.Table.TableHeader.CreateProject" />
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project: Project) => (
              <TableRow
                onClick={() => {
                  history.push({ pathname: `/project/${project.id}` });
                }}
                hover
                key={project.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell size="small" component="th" scope="row">
                  {project.title}
                </TableCell>
                <TableCell size="small">
                  <Box>{project.customer}</Box>
                </TableCell>
                <TableCell size="small">
                  <Box>{project.status}</Box>
                </TableCell>
                <TableCell size="small" align="right">
                  {checkForPrivilege(PrivilegeEnum.CAN_DELETE_PROJECT) ? (
                    <IconButton
                      sx={{ zIndex: 999 }}
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedProject(project);
                        setOpenDeleteDialog(true);
                      }}>
                      <Delete />
                    </IconButton>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ProjectCreateDialog
        openDialog={openCreateDialog}
        handleClose={() => {
          setOpenCreateDialog(false);
          history.replace("/project");
        }}
      />
      {selectedProject ? (
        <ProjectDeleteDialog
          openDialog={openDeleteDialog}
          handleClose={() => {
            setOpenDeleteDialog(false);
            history.replace("/project");
          }}
          project={selectedProject}
        />
      ) : null}
    </>
  );
};

export default ProjectTable;
