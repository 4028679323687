import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Checkbox,
  TextField,
  Typography,
  RadioGroup,
} from "@mui/material";
import { PersonAdd, Send, Person, Home, AssignmentInd, Apartment } from "@mui/icons-material";
import { DatePicker, LoadingButton } from "@mui/lab";
import { FormattedMessage } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { useAllRoles, Role } from "../../clients/roleClient";
import { CreateUser } from "../../clients/userClient";
import { FC, useContext, useEffect } from "react";
import theme from "../../assets/theming/theme";
import { UserContext } from "../../contexts/UserContext";
import { useUtils } from "../../utils/Utils";

interface EmployeeCreateModalProps {
  isCreateUserDialogActive: boolean;
}

const EmployeeCreateModal: FC<EmployeeCreateModalProps> = ({
  isCreateUserDialogActive: createUserDialogActive,
}: EmployeeCreateModalProps) => {
  const { allRolesResult } = useAllRoles();
  const { handleCreateUser, isConfirmButtonLoading, resetDialog } = useContext(UserContext);
  const { regexPatterns } = useUtils();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm<CreateUser>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      accountInfo: {
        email: "",
        username: null,
        picture: "",
        lastLogin: "",
        roles: [],
      },
      childAllowance: null,
      city: "",
      dayOfBirth: null,
      employeeId: null,
      entryDate: null,
      healthInsurance: "",
      highestDegree: "",
      houseNumber: "",
      iban: null,
      jobTitle: "",
      firstName: "",
      nationality: "",
      partOrFullTime: "fullTime",
      phonePrivate: null,
      phoneWork: null,
      probation: false,
      religion: "",
      resignationDate: null,
      socialSecurityNumber: null,
      street: "",
      lastName: "",
      taxClass: 1,
      taxIdentificationNumber: null,
      taxOffice: "",
      vacationDays: "",
      zipCode: "",
      privateEmail: "",
    },
  });

  useEffect(() => {
    reset();
  }, [resetDialog, reset]);

  const setNullOrString = (value: string) => {
    if (value === "") return null;
    else return value;
  };

  return (
    <Dialog
      maxWidth="md"
      data-testid="CreateUserDialog"
      open={createUserDialogActive}
      onClose={() => {
        resetDialog();
      }}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: "8px",
            color: "white",
            display: "grid",
            placeItems: "center",
            height: "42px",
            width: "42px",
            mr: 2,
          }}>
          <PersonAdd />
        </Box>
        <FormattedMessage id="EmployeeScreen.createUserDialog.title" />
      </DialogTitle>
      <DialogContent dividers sx={{ px: 3 }}>
        <Box
          component="form"
          onSubmit={() => {
            handleSubmit(handleCreateUser);
          }}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Card sx={{ backgroundColor: theme.palette.primary.light }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="overline"
                      sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                      <Person />
                      <FormattedMessage id="EmployeeScreen.createUserDialog.personalInformation.title" />
                    </Typography>
                    <Typography variant="caption" color="error" sx={{ ml: 1, fontStyle: "italic", fontSize: "0.75rem" }}>
                      <FormattedMessage id="EmployeeScreen.createUserDialog.personalInformation.requiredFields" />
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={4.5}>
                      <TextField
                        {...register("firstName", { required: true })}
                        required
                        autoFocus
                        error={!!errors.firstName}
                        fullWidth
                        data-testid="FirstNameTextField"
                        variant="standard"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        name="firstName"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.firstName" />}
                      />
                      <Collapse in={!!errors.firstName}>
                        <FormHelperText sx={{ fontSize: "9pt" }} error>
                          <FormattedMessage id="EmployeeScreen.createUserDialog.label.firstName.error" />
                        </FormHelperText>
                      </Collapse>
                    </Grid>
                    <Grid item md={4.5}>
                      <TextField
                        {...register("lastName", { required: true })}
                        required
                        error={!!errors.lastName}
                        data-testid="LastNameTextField"
                        fullWidth
                        variant="standard"
                        name="lastName"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.lastName" />}
                      />
                      <Collapse in={!!errors.lastName}>
                        <FormHelperText focused sx={{ fontSize: "9pt" }} error>
                          <FormattedMessage id="EmployeeScreen.createUserDialog.label.lastName.error" />
                        </FormHelperText>
                      </Collapse>
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        {...register("employeeId", { setValueAs: setNullOrString })}
                        data-testid="EmployeeIdTextField"
                        fullWidth
                        variant="standard"
                        name="employeeId"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.employeeId" />}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="dayOfBirth"
                        defaultValue={null}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.dayOfBirth" />}
                            inputFormat="dd.MM.yyyy"
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField data-testid="DayOfBirthDatePicker" name="dayOfBirth" fullWidth variant="standard" {...params} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <TextField
                        {...register("highestDegree")}
                        fullWidth
                        variant="standard"
                        data-testid="HighestDegreeTextField"
                        name="highestDegree"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.highestDegree" />}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("nationality")}
                        fullWidth
                        data-testid="NationalityTextField"
                        variant="standard"
                        name="nationality"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.nationality" />}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("religion")}
                        fullWidth
                        data-testid="ReligionTextField"
                        variant="standard"
                        name="religion"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.religion" />}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("taxOffice")}
                        fullWidth
                        data-testid="TaxOfficeTextField"
                        variant="standard"
                        name="taxOffice"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.taxOffice" />}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("taxIdentificationNumber", { setValueAs: setNullOrString })}
                        fullWidth
                        data-testid="TaxIdTextField"
                        variant="standard"
                        name="taxIdentificationNumber"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.taxIdentificationNumber" />}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("healthInsurance")}
                        fullWidth
                        data-testid="HealthInsuranceTextField"
                        variant="standard"
                        name="healthInsurance"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.healthInsurance" />}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("socialSecurityNumber", { setValueAs: setNullOrString })}
                        fullWidth
                        data-testid="SocialSecurityTextField"
                        variant="standard"
                        name="socialSecurityNumber"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.socialSecurityNumber" />}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <TextField
                        {...register("iban", { setValueAs: setNullOrString })}
                        fullWidth
                        data-testid="IbanTextField"
                        variant="standard"
                        name="iban"
                        error={!!errors.iban}
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.iban" />}
                      />
                      <Collapse in={!!errors.iban}>
                        <FormHelperText sx={{ fontSize: "9pt" }} error>
                          <FormattedMessage id="EmployeeScreen.createUserDialog.label.iban.error" />
                        </FormHelperText>
                      </Collapse>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl fullWidth>
                        <InputLabel sx={{ left: -14, bottom: -4 }} id="taxClassSelectLabel">
                          <FormattedMessage id="EmployeeScreen.createUserDialog.label.taxClass" />
                        </InputLabel>
                        <Select
                          {...(register("taxClass"), { setValueAs: setNullOrString })}
                          name="taxClass"
                          labelId="taxClassSelectLabel"
                          variant="standard"
                          data-testid="TaxClassSelect">
                          {[...Array(6)].map((e, i) => (
                            <MenuItem key={i} value={i + 1} data-testid={`TaxClassOption-${i + 1}`}>
                              <FormattedMessage id="EmployeeScreen.createUserDialog.label.taxClass" /> {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={5.5}>
              <Card sx={{ backgroundColor: theme.palette.primary.light }}>
                <CardContent>
                  <Typography
                    variant="overline"
                    sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                    <Home />
                    <FormattedMessage id="EmployeeScreen.createUserDialog.address.title" />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={9}>
                      <TextField
                        {...register("street")}
                        fullWidth
                        data-testid="StreetTextField"
                        variant="standard"
                        name="street"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.street" />}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        {...register("houseNumber")}
                        fullWidth
                        data-testid="HouseNrTextField"
                        variant="standard"
                        name="houseNumber"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.houseNumber" />}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        {...register("zipCode")}
                        fullWidth
                        data-testid="ZipCodeTextField"
                        variant="standard"
                        name="zipCode"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.zipCode" />}
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]{0,5}" }}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <TextField
                        {...register("city")}
                        fullWidth
                        data-testid="CityTextField"
                        variant="standard"
                        name="city"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.city" />}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6.5}>
              <Card sx={{ backgroundColor: theme.palette.primary.light }}>
                <CardContent>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="overline"
                      sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                      <AssignmentInd sx={{ mb: 0.5 }} />
                      <FormattedMessage id="EmployeeScreen.createUserDialog.contactInformation.title" />
                    </Typography>
                    <Typography variant="caption" color="error" sx={{ ml: 1, fontStyle: "italic", fontSize: "0.75rem" }}>
                      <FormattedMessage id="EmployeeScreen.createUserDialog.personalInformation.requiredFields" />
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Controller
                        name="accountInfo.email"
                        rules={{
                          required: true,
                          pattern: regexPatterns.email,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            onChange={onChange}
                            onBlur={onBlur}
                            required
                            value={value}
                            fullWidth
                            type="email"
                            error={!!errors.accountInfo?.email}
                            variant="standard"
                            data-testid="EmailTextField"
                            name="email"
                            label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.email" />}
                          />
                        )}
                      />
                      <Collapse in={!!errors.accountInfo?.email}>
                        <FormHelperText sx={{ fontSize: "9pt" }} error>
                          <FormattedMessage id="EmployeeScreen.createUserDialog.label.email.error" />
                        </FormHelperText>
                      </Collapse>
                    </Grid>
                    <Grid item md={6}>
                      <Controller
                        name="privateEmail"
                        rules={{
                          pattern: regexPatterns.email,
                        }}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            fullWidth
                            type="email"
                            variant="standard"
                            data-testid="EmailTextField"
                            name="privateEmail"
                            label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.privateEmail" />}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("phonePrivate", { setValueAs: setNullOrString })}
                        fullWidth
                        data-testid="PhronePrivateTextField"
                        variant="standard"
                        name="phonePrivate"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.phonePrivate" />}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        {...register("phoneWork", { setValueAs: setNullOrString })}
                        fullWidth
                        data-testid="PhoneWorkTextField"
                        variant="standard"
                        name="phoneWork"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.phoneWork" />}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Card sx={{ backgroundColor: theme.palette.primary.light }}>
                <CardContent>
                  <Typography
                    variant="overline"
                    sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                    <Apartment />
                    <FormattedMessage id="EmployeeScreen.createUserDialog.businessInformation.title" />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <FormControl>
                        <FormLabel id="partOrFullTimeLabel" sx={{ fontSize: "0.75rem" }}>
                          <FormattedMessage id="EmployeeScreen.createUserDialog.label.partOrFullTime" />
                        </FormLabel>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            border: (theme) => `1px solid ${theme.palette.divider}`,
                            pl: 1.75,
                            borderRadius: 1,
                          }}>
                          <Controller
                            name="partOrFullTime"
                            control={control}
                            defaultValue="fullTime"
                            render={({ field: { onChange, onBlur, value } }) => (
                              <RadioGroup
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                name="partOrFullTime"
                                data-testid="PartFullTimeTextField"
                                aria-labelledby="partOrFullTimeLabel"
                                row>
                                <FormControlLabel
                                  value="fullTime"
                                  label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.partOrFullTime.fullTime" />}
                                  control={<Radio data-testid="FullTimeRadio" size="small" />}
                                />
                                <FormControlLabel
                                  value="partTime"
                                  label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.partOrFullTime.partTime" />}
                                  control={<Radio data-testid="PartTimeRadio" size="small" />}
                                />
                              </RadioGroup>
                            )}
                          />
                          <Divider variant="middle" orientation="vertical" flexItem />
                          <FormControlLabel
                            sx={{ ml: 1 }}
                            label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.probation" />}
                            control={<Checkbox {...register("probation")} data-testid="ProbationCheckbox" />}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl fullWidth sx={{ mt: 1.2 }}>
                        <InputLabel id="rolesLabel" sx={{ left: -14, bottom: -4 }}>
                          <FormattedMessage id="EmployeeScreen.createUserDialog.label.roles" />
                        </InputLabel>
                        <Select
                          {...register("accountInfo.roles")}
                          defaultValue={[]}
                          multiple
                          labelId="rolesLabel"
                          variant="standard"
                          MenuProps={{
                            sx: {
                              "&& .Mui-selected": { backgroundColor: theme.palette.primary.light },
                            },
                            PaperProps: { style: { maxHeight: 210 } },
                          }}
                          data-testid="RoleSelect"
                          renderValue={(selectedRoles: string[]) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                              {selectedRoles.map((role: string) => (
                                <Chip color="primary" key={role} label={role} />
                              ))}
                            </Box>
                          )}>
                          {allRolesResult.status === "loaded" ? (
                            allRolesResult.payload.map((role: Role) => (
                              <MenuItem data-testid={`RoleOption-${role.name}`} key={role.id} value={role.name}>
                                <Typography>{role.name}</Typography>
                                <Chip
                                  sx={{ my: "auto", ml: "auto", color: role.active ? "#1e6483" : "#d32f2f" }}
                                  size="small"
                                  label={
                                    <FormattedMessage
                                      id={`EmployeeScreen.createUserDialog.select.roles.${role.active ? "active" : "inactive"}`}
                                    />
                                  }
                                />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>Keine Rollen verfügbar</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={8}>
                      <TextField
                        {...register("jobTitle")}
                        data-testid="JobTitleTextField"
                        fullWidth
                        variant="standard"
                        name="jobTitle"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.jobTitle" />}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        {...register("childAllowance")}
                        fullWidth
                        variant="standard"
                        data-testid="ChildAllowanceTextField"
                        name="childAllowance"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.childAllowance" />}
                        type="number"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="entryDate"
                        defaultValue={null}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.entryDate" />}
                            inputFormat="dd.MM.yyyy"
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField data-testid="EntryDatePicker" name="entryDate" fullWidth variant="standard" {...params} />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="resignationDate"
                        defaultValue={null}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.resignationDate" />}
                            inputFormat="dd.MM.yyyy"
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField
                                data-testid="ResignationDatePicker"
                                name="resignationDate"
                                fullWidth
                                variant="standard"
                                {...params}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        {...register("vacationDays")}
                        fullWidth
                        data-testid="VacationDaysTextField"
                        variant="standard"
                        name="vacationDays"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.vacationDays" />}
                        type="number"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*,+[0-9]{0, 2}" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {parseInt(getValues("vacationDays") + "") === 1 ? "Tag" : "Tage"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            resetDialog();
          }}>
          <FormattedMessage id="EmployeeScreen.createUserDialog.button.cancel" />
        </Button>
        <LoadingButton
          data-testid="CreateUserConfirmButton"
          loading={isConfirmButtonLoading}
          loadingPosition="end"
          endIcon={<Send />}
          onClick={handleSubmit(handleCreateUser)}
          variant="contained">
          <FormattedMessage id="EmployeeScreen.createUserDialog.button.confirm" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeCreateModal;
