import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CreateProjectDTO } from "../../clients/projectClient";
import { ProjectContext } from "../../contexts/ProjectContext";

interface ProjectCreateDialogProps {
  openDialog: boolean;
  handleClose: () => void;
}

const MIN_TITLE_LENGTH = 1;

const ProjectCreateDialog: FC<ProjectCreateDialogProps> = ({ openDialog, handleClose }: ProjectCreateDialogProps) => {
  const [title, setTitle] = useState<string>();
  const { createProject } = useContext(ProjectContext);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case "Enter":
        confirmCreateProject();
        break;
      case "Escape":
        handleClose();
        break;
      default:
        break;
    }
  };

  const confirmCreateProject = () => {
    if (title) {
      createProject({ title } as CreateProjectDTO);
      handleClose();
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={openDialog} onClose={handleClose} data-testid="ProjectCreateDialog">
      <DialogTitle id="alert-dialog-title">{<FormattedMessage id="ProjectScreen.CreateDialog.Title" />}</DialogTitle>
      <DialogContent onKeyUp={handleKeyUp}>
        <TextField
          autoFocus
          fullWidth
          label={<FormattedMessage id="ProjectScreen.CreateDialog.Message" />}
          size="small"
          variant="standard"
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          // !! castet title zu Boolean
          error={!!title && title.length <= MIN_TITLE_LENGTH}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          <FormattedMessage id="ProjectScreen.CreateDialog.Cancel" />
        </Button>
        <Button color="primary" variant="contained" onClick={() => confirmCreateProject()}>
          <FormattedMessage id="ProjectScreen.CreateDialog.Create" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectCreateDialog;
