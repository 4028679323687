import { useCallback, useEffect } from "react";
import { Result, useFetch } from "./useFetch";

export interface Salary {
  id: string;
  active: boolean;
  date: string;
  correctionFactor: boolean;
  targetSalary: number;
  partTimeFactor: number;
  variablePart: number;
  mobilityAllowance: string;
  monthlyAmount: number;
  notes: string;
}

export const useDeleteSalary = (): {
  deleteSalaryResult: Result<null>;
  deleteSalary: (salaryId: string) => void;
} => {
  const { fetcher, result } = useFetch<null>();
  const deleteSalary = useCallback(
    (salaryId: string) => {
      fetcher(process.env.REACT_APP_SERVER + `/salary/${salaryId}`, { method: "DELETE" });
    },
    [fetcher],
  );
  return {
    deleteSalary: deleteSalary,
    deleteSalaryResult: result,
  };
};
export const useCreateSalary = (): {
  createSalaryResult: Result<Salary | string>;
  createSalary: (userId: string, salary: Salary) => void;
} => {
  const { fetcher, result } = useFetch<Salary>();
  const createSalary = useCallback(
    (userId: string, salary: Salary) => {
      fetcher(process.env.REACT_APP_SERVER + `/salary/${userId}`, { method: "POST", body: JSON.stringify(salary) });
    },
    [fetcher],
  );
  return {
    createSalary: createSalary,
    createSalaryResult: result,
  };
};
export const useSalary = (
  userId: string,
): {
  salariesResult: Result<Salary[]>;
  refreshSalary: () => void;
} => {
  const { fetcher, result } = useFetch<Salary[]>();

  const getSalary = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + `/salary/${userId}`);
  }, [fetcher, userId]);

  useEffect(() => {
    getSalary();
  }, [getSalary]);

  return {
    refreshSalary: getSalary,
    salariesResult: result,
  };
};

export const useUpdateSalary = (): {
  updateSalaryResult: Result<Salary>;
  updateSalary: (salary: Salary) => void;
} => {
  const { fetcher, result } = useFetch<Salary>();
  const updateSalary = useCallback(
    (salary: Salary) => {
      fetcher(process.env.REACT_APP_SERVER + `/salary/${salary.id}`, { method: "PUT", body: JSON.stringify(salary) });
    },
    [fetcher],
  );

  return {
    updateSalaryResult: result,
    updateSalary: updateSalary,
  };
};
