import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import logo from "../../assets/images/moguru.png";
import ErrorMessage from "../ErrorMessage";
import { useLogin } from "../../clients/loginClient";

const LoginScreen: FC = () => {
  const { handleGoogleSuccessResponse, handleGoogleErrorResponse, result } = useLogin();

  return (
    <div
      style={{
        backgroundColor: "#282c34",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontSize: 12,
      }}>
      <Paper elevation={2} style={{ padding: 34, color: "#1E6483", marginBottom: "10%" }}>
        <img src={logo} style={{ maxWidth: 400 }} alt="logo" />
        <Typography variant="h2" sx={{ mb: 4 }}>
          A38
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID ?? ""}>
            <GoogleLogin onSuccess={handleGoogleSuccessResponse} onError={handleGoogleErrorResponse} useOneTap theme="filled_blue" />
          </GoogleOAuthProvider>
        </Box>

        {result.status === "loading" ? (
          <Box sx={{ mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : result.status === "error" ? (
          <ErrorMessage messageId={result.messageId} />
        ) : null}
      </Paper>
    </div>
  );
};

export default LoginScreen;
