import { Edit, Save, Cancel, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  TextField,
  Divider,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import theme from "../../assets/theming/theme";
import { Keyinformation } from "../../clients/projectClient";
import { User } from "../../clients/userClient";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";

import { ProjectContext } from "../../contexts/ProjectContext";
import { UserContext } from "../../contexts/UserContext";
import { PrivilegeEnum } from "../RolesScreen/PrivilegeEnum";

const ProjectKeyinformation: FC = () => {
  const { keyinformation, updateKeyinformation } = useContext(ProjectContext);
  const { projectId } = useParams<{ projectId: string }>();
  const { checkForPrivilege } = useContext(PrivilegeContext);
  const { users } = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, reset, getValues, control } = useForm<Keyinformation>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: keyinformation,
  });

  const getUserByUsername = (username: string): User | null => {
    if (username) return users.find(({ accountInfo }) => accountInfo.username === username) as User;
    return null;
  };

  useEffect(() => {
    reset(keyinformation);
  }, [keyinformation, reset]);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 2 }} style={{ flex: 1 }}>
      <Box
        maxWidth="850px"
        component="form"
        onSubmit={() => {
          handleSubmit(updateKeyinformation);
        }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <IconButton
                onClick={() => history.push({ pathname: `/project/${projectId}/` })}
                sx={{ "&:hover": { color: theme.palette.primary.main } }}>
                <ArrowBack color="primary" />
              </IconButton>
              <Typography variant="h6" sx={{ ml: 2 }}>
                <FormattedMessage id="ProjectKeyInformation.Table.title" />
              </Typography>
              {editMode ? (
                <Typography variant="caption" color="error" sx={{ ml: 1, fontStyle: "italic", fontSize: "0.75rem" }}>
                  <FormattedMessage id="EmployeeScreen.createUserDialog.personalInformation.requiredFields" />
                </Typography>
              ) : null}
              <Box sx={{ display: "flex", justifyContent: "flex-end", ml: "auto" }}>
                {checkForPrivilege(PrivilegeEnum.CAN_EDIT_KEYINFORMATION) ? (
                  <>
                    {!editMode ? (
                      <Box>
                        <IconButton sx={{ "&:hover": { color: theme.palette.primary.main } }} onClick={() => setEditMode(true)}>
                          <Edit />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box>
                        <IconButton
                          color="primary"
                          onClick={handleSubmit((keyinformation) => {
                            updateKeyinformation({
                              ...keyinformation,
                              contactPersonExtensionsDto: getUserByUsername(getValues("contactPersonExtensionsDto.accountInfo.username")),
                              contactPersonAccountingDto: getUserByUsername(getValues("contactPersonAccountingDto.accountInfo.username")),
                            });
                            setEditMode(false);
                          })}>
                          <Save />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setEditMode(false);
                            reset(keyinformation);
                          }}>
                          <Cancel />
                        </IconButton>
                      </Box>
                    )}
                  </>
                ) : null}
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  {/* Aktueller AP für Verlängerungen */}
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <InputLabel sx={{ left: -14, bottom: -4 }} id="contactPersonExtensionsDtoLabel">
                        <FormattedMessage id="ProjectKeyInformation.label.currentRenewal" />
                      </InputLabel>
                      <Controller
                        name="contactPersonExtensionsDto.accountInfo.username"
                        control={control}
                        defaultValue={getValues("contactPersonExtensionsDto.accountInfo.username") ?? ""}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            labelId="contactPersonExtensionsDtoLabel"
                            variant="standard"
                            inputProps={{ readOnly: !editMode }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                },
                              },
                            }}
                            data-testid="ContactPersonExtensionsSelect">
                            <MenuItem value="">
                              <Typography sx={{ fontStyle: "italic" }}>
                                <FormattedMessage id="ProjectKeyInformation.label.noCurrentRenewal" />
                              </Typography>
                            </MenuItem>
                            <Divider />
                            {users.map((user) => (
                              <MenuItem key={user.id} value={user.accountInfo.username}>
                                {user.accountInfo.username}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* Link Projektverträge */}
                  <Grid item md={6}>
                    <TextField
                      {...register("urlProjectContracts")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="urlProjectContracts"
                      label={<FormattedMessage id="ProjectKeyInformation.label.linkProjectContracts" />}
                    />
                  </Grid>
                  {/* Vermittlungsagentur  */}
                  <Grid item md={6}>
                    <TextField
                      {...register("placementAgency")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="placementAgency"
                      label={<FormattedMessage id="ProjectKeyInformation.label.placementAgency" />}
                    />
                  </Grid>
                  {/* Crefo Ja/Nein */}
                  <Grid item md={6}>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <label>
                      <input type="checkbox" {...register("crefo")} name="crefo" disabled={!editMode} />
                      <FormattedMessage id="ProjectKeyInformation.label.crefo" />
                    </label>
                  </Grid>
                  {/* AP Vermittler */}
                  <Grid item md={6}>
                    <TextField
                      {...register("contactPersonIntermediary")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="contactPersonIntermediary"
                      label={<FormattedMessage id="ProjectKeyInformation.label.agent" />}
                    />
                  </Grid>
                  {/* AP Buchhaltung */}
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <InputLabel sx={{ left: -14, bottom: -4 }} id="contactPersonAccountingDtoLabel">
                        <FormattedMessage id="ProjectKeyInformation.label.accounting" />
                      </InputLabel>
                      <Controller
                        name="contactPersonAccountingDto.accountInfo.username"
                        control={control}
                        defaultValue={getValues("contactPersonAccountingDto.accountInfo.username") ?? ""}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            labelId="contactPersonAccountingDtoLabel"
                            variant="standard"
                            inputProps={{ readOnly: !editMode }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 224,
                                },
                              },
                            }}
                            data-testid="ContactPersonAccountingSelect">
                            <MenuItem value="">
                              <Typography sx={{ fontStyle: "italic" }}>
                                <FormattedMessage id="ProjectKeyInformation.label.noAccounting" />
                              </Typography>
                            </MenuItem>
                            <Divider />
                            {users.map((user) => (
                              <MenuItem key={user.id} value={user.accountInfo.username}>
                                {user.accountInfo.username}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* Projektnummer/-name */}
                  <Grid item md={6}>
                    <TextField
                      {...register("projectNumber")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="projectNumber"
                      label={<FormattedMessage id="ProjectKeyInformation.label.projectNumberName" />}
                    />
                  </Grid>
                  {/* Auftragsbeschreibung */}
                  <Grid item md={6}>
                    <TextField
                      {...register("contractDescription")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="contractDescription"
                      label={<FormattedMessage id="ProjectKeyInformation.label.orderDescription" />}
                    />
                  </Grid>
                  {/* Aktives Projekt */}
                  <Grid item md={6}>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <label>
                      <input type="checkbox" {...register("active")} name="active" disabled={!editMode} />
                      <FormattedMessage id="ProjectKeyInformation.label.activeProject" />
                    </label>
                  </Grid>
                  {/* Vertragsdauer */}
                  <Grid item md={6}>
                    <TextField
                      {...register("contractDuration")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="contractDuration"
                      label={<FormattedMessage id="ProjectKeyInformation.label.contractDuration" />}
                    />
                  </Grid>
                  {/* Offen PT vom (vorherigen) Projekt */}
                  <Grid item md={6}>
                    <TextField
                      {...register("openManDays")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      type="number"
                      variant="standard"
                      name="openManDays"
                      label={<FormattedMessage id="ProjectKeyInformation.label.openProjectDays" />}
                    />
                  </Grid>
                  {/* Stundensatz */}
                  <Grid item md={6}>
                    <TextField
                      {...register("hourRate")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      type="number"
                      variant="standard"
                      name="hourRate"
                      label={<FormattedMessage id="ProjectKeyInformation.label.hourlyRate" />}
                    />
                  </Grid>
                  {/* Remote */}
                  <Grid item md={6}>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <label>
                      <input type="checkbox" {...register("remote")} name="remote" disabled={!editMode} />
                      <FormattedMessage id="ProjectKeyInformation.label.remote" />
                    </label>
                  </Grid>
                  {/* Ziel Tagessatz */}
                  <Grid item md={6}>
                    <TextField
                      {...register("dailyRateTarget")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      type="number"
                      variant="standard"
                      name="dailyRateTarget"
                      label={<FormattedMessage id="ProjectKeyInformation.label.targetDailyRate" />}
                    />
                  </Grid>
                  {/* Std/PT */}
                  <Grid item md={6}>
                    <TextField
                      {...register("hourManDay")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      type="number"
                      variant="standard"
                      name="hourManDay"
                      label={<FormattedMessage id="ProjectKeyInformation.label.hours" />}
                    />
                  </Grid>
                  {/* Rechnungs-/Timesheettool */}
                  <Grid item md={6}>
                    <TextField
                      {...register("timesheetTool")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="timesheetTool"
                      label={<FormattedMessage id="ProjectKeyInformation.label.invoicingTool" />}
                    />
                  </Grid>
                  {/* Rechnung versenden an (Mail) */}
                  <Grid item md={6}>
                    <TextField
                      {...register("invoiceEmail")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="invoiceEmail"
                      label={<FormattedMessage id="ProjectKeyInformation.label.invoiceMail" />}
                    />
                  </Grid>
                  {/* Rechnungsadresse */}
                  <Grid item md={6}>
                    <TextField
                      {...register("billingAddress")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="billingAddress"
                      label={<FormattedMessage id="ProjectKeyInformation.label.billingAddress" />}
                    />
                  </Grid>
                  {/* Gutschriftenverfahren */}
                  <Grid item md={6}>
                    <TextField
                      {...register("creditNotesProcedure")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="creditNotesProcedure"
                      label={<FormattedMessage id="ProjectKeyInformation.label.creditMethod" />}
                    />
                  </Grid>

                  {/* Zahlungsziel */}
                  <Grid item md={6}>
                    <TextField
                      {...register("invoiceTarget")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="invoiceTarget"
                      label={<FormattedMessage id="ProjectKeyInformation.label.paymentTarget" />}
                    />
                  </Grid>
                  {/* Besonderheiten */}
                  <Grid item md={6}>
                    <TextField
                      {...register("features")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="features"
                      label={<FormattedMessage id="ProjectKeyInformation.label.features" />}
                    />
                  </Grid>
                  {/* Weg der Rechnungsstellung */}
                  <Grid item md={6}>
                    <TextField
                      {...register("invoiceMethod")}
                      InputLabelProps={{ shrink: true, focused: false }}
                      inputProps={{ readOnly: !editMode }}
                      fullWidth
                      variant="standard"
                      name="invoiceMethod"
                      label={<FormattedMessage id="ProjectKeyInformation.label.wayOfBilling" />}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ProjectKeyinformation;
