import { useCallback, useEffect } from "react";
import { Result, useFetch } from "./useFetch";
import { User } from "./userClient";

export interface Project {
  id: string;
  title: string;
  customer: string;
  description?: string;
  projectStart: string;
  budget: number;
  status: string;
  statusDetails: string;
  contactPersons: User[];
  projectEmployees: ProjectEmployee[];
  external: boolean;
}

export interface ProjectEmployee {
  userDto: User;
  roleDescription: string;
  active: boolean;
  activeFrom: string | null;
  activeTo: string | null;
  projectId: string;
}

export interface CreateProjectDTO {
  title: string;
}

export interface UpdateProjectDTO {
  id: string;
  title?: string;
  customer?: string;
  projectStart?: string;
  budget?: number;
  status?: string;
  statusDetails?: string;
  contactPersons?: string[];
  projectEmployees?: ProjectEmployee[];
  external?: boolean;
}

export interface Keyinformation {
  active: boolean;
  billingAddress: string;
  contactPersonAccountingDto: User | null;
  contactPersonExtensionsDto: User | null;
  contactPersonIntermediary: string;
  contractDescription: string;
  contractDuration: string;
  creditNotesProcedure: string;
  crefo: boolean;
  customer: string;
  dailyRateTarget: number;
  features: string;
  hourManDay: number;
  hourRate: number;
  invoiceEmail: string;
  invoiceMethod: string;
  invoiceTarget: string;
  openManDays: number;
  placementAgency: string;
  projectNumber: string;
  remote: boolean;
  timesheetTool: string;
  timesheetUrl: string;
  urlProjectContracts: string;
}

export const useAllProjects = (): { projectsResult: Result<Project[]>; refreshProjects: () => void } => {
  const { fetcher, result } = useFetch<Project[]>();

  const getProjects = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + "/project");
  }, [fetcher]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return {
    projectsResult: result,
    refreshProjects: getProjects,
  };
};

export const useDeleteProject = (): {
  deleteProjectResult: Result<null>;
  deleteProject: (projectId: string) => void;
} => {
  const { fetcher, result } = useFetch<null>();
  const deleteProject = useCallback(
    (projectId: string) => {
      fetcher(process.env.REACT_APP_SERVER + `/project/${projectId}`, { method: "DELETE" });
    },
    [fetcher],
  );
  return {
    deleteProject: deleteProject,
    deleteProjectResult: result,
  };
};

export const useCreateProject = (): {
  createProjectResult: Result<Project | string>;
  createProject: (project: CreateProjectDTO) => void;
} => {
  const { fetcher, result } = useFetch<Project>();
  const createProject = useCallback(
    (createProjectDTO: CreateProjectDTO) => {
      fetcher(process.env.REACT_APP_SERVER + "/project", { method: "POST", body: JSON.stringify(createProjectDTO) });
    },
    [fetcher],
  );
  return {
    createProject: createProject,
    createProjectResult: result,
  };
};

export const useUpdateProject = (
  projectId: string,
): {
  updateProjectResult: Result<Project>;
  updateProject: (project: UpdateProjectDTO) => void;
} => {
  const { fetcher, result } = useFetch<Project>();
  const updateProject = useCallback(
    (project: UpdateProjectDTO) => {
      fetcher(process.env.REACT_APP_SERVER + `/project/${projectId}`, { method: "PUT", body: JSON.stringify(project) });
    },
    [fetcher, projectId],
  );
  return {
    updateProject,
    updateProjectResult: result,
  };
};

export const useProject = (
  projectId: string,
): {
  projectResult: Result<Project>;
  refreshProject: () => void;
} => {
  const { fetcher, result } = useFetch<Project>();
  const getProject = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + `/project/${projectId}`);
  }, [fetcher, projectId]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  return {
    refreshProject: getProject,
    projectResult: result,
  };
};

export const useProjectKeyinformation = (
  projectId: string,
): {
  keyinformationResult: Result<Keyinformation>;
  refreshKeyinformation: () => void;
} => {
  const { fetcher, result } = useFetch<Keyinformation>();

  const getKeyinformation = useCallback(() => {
    if (projectId) fetcher(process.env.REACT_APP_SERVER + `/project/${projectId}/keyinformation`);
  }, [fetcher, projectId]);

  useEffect(() => {
    getKeyinformation();
  }, [getKeyinformation]);

  return {
    refreshKeyinformation: getKeyinformation,
    keyinformationResult: result,
  };
};

export const useUpdateProjectKeyinformation = (
  projectId: string,
): {
  updateProjectKeyinformationResult: Result<Keyinformation>;
  updateProjectKeyinformation: (keyinformation: Keyinformation) => void;
} => {
  const { fetcher, result } = useFetch<Keyinformation>();

  const updateProjectKeyinformation = useCallback(
    (project: Keyinformation) => {
      fetcher(process.env.REACT_APP_SERVER + `/project/${projectId}/keyinformation`, { method: "PUT", body: JSON.stringify(project) });
    },
    [fetcher, projectId],
  );

  return {
    updateProjectKeyinformation,
    updateProjectKeyinformationResult: result,
  };
};
