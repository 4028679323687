import { useCallback, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { UserData } from "./loginClient";

interface ResultInitial {
  status: "initial";
}

interface ResultLoading {
  status: "loading";
}

export interface ResultLoaded<T> {
  status: "loaded";
  payload: T;
  responseStatusCode: number | null;
}

interface ResultLoadedEmpty {
  status: "loadedEmpty";
}

interface ResultError {
  status: "error";
  responseErrorCode: number | null;
  messageId: string;
}

export type Result<T> = ResultInitial | ResultLoading | ResultLoadedEmpty | ResultLoaded<T> | ResultError;

// return function that works just like fetch, handling auth & parsing json into result
export const useFetch = <T,>(): {
  result: Result<T>;
  fetcher: (input: RequestInfo, init?: RequestInit | undefined) => void;
  resetResult: () => void;
} => {
  const [cookie, setCookie, removeCookie] = useCookies<"userData", { userData: UserData }>(["userData"]);
  const abortController = useRef(new AbortController());

  const [result, setResult] = useState<Result<T>>({
    status: "initial",
  });

  const fetcher = useCallback(
    async (input: RequestInfo, init?: RequestInit | undefined) => {
      abortController.current.abort();
      abortController.current = new AbortController();

      setResult({ status: "loading" });
      try {
        const response = await fetch(input, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `${cookie.userData?.tokens?.accessToken ?? ""}`,
          },
          signal: abortController.current.signal,
          ...init,
        });
        if (response.ok) {
          if (response.status === 204) {
            setResult({ status: "loadedEmpty" });
          } else {
            const data: T = await response.json();
            setResult({ status: "loaded", payload: data, responseStatusCode: response.status });
          }
        } else {
          if (response.status === 401) {
            // token is invalid -> try refresh
            const refreshResponse = await fetch(process.env.REACT_APP_SERVER + "/session/refresh", {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `${cookie.userData?.tokens?.refreshToken ?? ""}`,
              },
            });
            if (refreshResponse.ok) {
              const refreshData: UserData = await refreshResponse.json();
              setCookie("userData", refreshData);
            } else {
              removeCookie("userData");
            }
          }
          setResult({ status: "error", responseErrorCode: response.status, messageId: response.statusText });
        }
      } catch (error) {
        const err = error as Error;
        if (err.name !== "AbortError") setResult({ status: "error", responseErrorCode: null, messageId: "General.error.server" });
      }
    },
    [cookie.userData?.tokens?.accessToken, cookie.userData?.tokens?.refreshToken, removeCookie, setCookie],
  );

  const resetResult = useCallback(() => {
    abortController.current.abort();
    setResult({ status: "initial" });
  }, []);

  return { fetcher, result, resetResult };
};
