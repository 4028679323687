import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { FC, useState, useEffect, useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { User } from "../../clients/userClient";
import { Project, ProjectEmployee, useProject } from "../../clients/projectClient";
import { DatePicker } from "@mui/lab";
import { Inbox, Save } from "@mui/icons-material";
import theme from "../../assets/theming/theme";
import { useForm, Controller } from "react-hook-form";
import { ProjectContext } from "../../contexts/ProjectContext";
import { UserContext } from "../../contexts/UserContext";

interface EditProjectEmployeesModalProps {
  openDialog: boolean;
  setOpenDialog: (newProjectEmployeeDialog: boolean) => void;
}

const EditProjectEmployeesModal: FC<EditProjectEmployeesModalProps> = ({ openDialog, setOpenDialog }: EditProjectEmployeesModalProps) => {
  const { users } = useContext(UserContext);
  const { currentProject, updateProjectEmployees } = useContext(ProjectContext);
  const { projectResult } = useProject(currentProject.id);
  const { projectEmployees } = currentProject;

  const getProjectEmployeesFromUsers = useCallback(
    (users: User[]): ProjectEmployee[] => {
      const tempEmployeeList: ProjectEmployee[] = [];
      users.forEach((user) => {
        tempEmployeeList.push(
          projectEmployees.some((projectEmployee) => projectEmployee.userDto.id === user.id)
            ? projectEmployees[projectEmployees.findIndex((projectEmployee) => projectEmployee.userDto.id === user.id)]
            : {
                userDto: user,
                active: false,
                activeFrom: null,
                activeTo: null,
                projectId: currentProject.id,
                roleDescription: "",
              },
        );
      });
      return tempEmployeeList;
    },
    [projectEmployees, currentProject],
  );

  const getDefaultValues = useCallback(() => {
    return { ...currentProject, projectEmployees: getProjectEmployeesFromUsers(users) };
  }, [currentProject, getProjectEmployeesFromUsers, users]);

  const { control, reset, handleSubmit, getValues, setValue } = useForm<Project>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: getDefaultValues(),
  });

  const [search, setSearch] = useState("");
  const [employeeDisplayList, setEmployeeDisplayList] = useState<ProjectEmployee[]>([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [confirmationLoading, setConfirmationLoading] = useState<boolean>(false);

  const confirmSelection = () => {
    setConfirmationLoading(true);

    const selectedEmployees: ProjectEmployee[] = [];

    selectedEmployeeIds.forEach((selectedEmployeeId) => {
      selectedEmployees.push(
        getValues("projectEmployees").find((projectEmployee) => projectEmployee.userDto.id === selectedEmployeeId) as ProjectEmployee,
      );
    });

    updateProjectEmployees(selectedEmployees);
    handleClose();
  };

  const handleClose = useCallback(() => {
    setConfirmationLoading(false);
    setOpenDialog(false);
    setSelectedEmployeeIds(projectEmployees.map((projectEmployee) => projectEmployee.userDto.id));
    setSearch("");
    reset();
  }, [setOpenDialog, projectEmployees, reset]);

  const isUserSelected = (projectEmployeeId: string): boolean => {
    return selectedEmployeeIds.some((selectedEmployeeId) => selectedEmployeeId === projectEmployeeId);
  };

  const toggleUserSelection = (projectEmployeeId: string) => {
    if (isUserSelected(projectEmployeeId))
      setSelectedEmployeeIds(selectedEmployeeIds.filter((selectedEmployeeId) => selectedEmployeeId !== projectEmployeeId));
    else setSelectedEmployeeIds([...selectedEmployeeIds, projectEmployeeId]);
  };

  useEffect(() => {
    if (projectResult.status === "loaded") handleClose();
  }, [projectResult, handleClose]);

  useEffect(() => {
    if (search !== "") {
      setEmployeeDisplayList(
        getValues("projectEmployees").filter((projectEmployee) =>
          projectEmployee.userDto.accountInfo.username.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else setEmployeeDisplayList(getValues("projectEmployees"));
  }, [search, getValues]);

  useEffect(() => {
    if (employeeDisplayList.length <= 0) {
      setEmployeeDisplayList(getProjectEmployeesFromUsers(users));
      setValue("projectEmployees", getProjectEmployeesFromUsers(users));
      setSelectedEmployeeIds(projectEmployees.map((projectEmployee) => projectEmployee.userDto.id));
      reset(getDefaultValues());
    }
  }, [employeeDisplayList, getDefaultValues, getProjectEmployeesFromUsers, projectEmployees, reset, setValue, users]);

  return (
    <Dialog maxWidth="md" fullWidth open={openDialog} onClose={handleClose} data-testid="EditProjectEmployeesModal">
      <DialogTitle>
        <FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.title" />
      </DialogTitle>
      <DialogContent dividers sx={{ pb: 0 }}>
        <TextField
          sx={{ mb: 2 }}
          autoFocus
          variant="standard"
          label="Name"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {employeeDisplayList.length > 0 ? (
          <Stack spacing={1} sx={{ maxHeight: "300px", overflow: "auto", pb: 2 }}>
            {employeeDisplayList.map((employee, i) => (
              <Box
                component={Card}
                key={employee.userDto.id}
                sx={{
                  alignItems: "center",
                  backgroundColor: isUserSelected(employee.userDto.id) ? theme.palette.primary.light : "",
                  borderColor: isUserSelected(employee.userDto.id) ? theme.palette.primary.main : "",
                  color: isUserSelected(employee.userDto.id) ? theme.palette.primary.main : "black",
                  display: "flex",
                  gap: 2,
                  overflow: "visible",
                  px: 1.5,
                  py: 1,
                }}>
                <Avatar sx={{ width: "44px", height: "44px" }} src={employee.userDto.accountInfo.picture} />
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    {employee.userDto.accountInfo.username}
                  </Typography>
                  <Typography variant="body2">{employee.userDto.accountInfo.email}</Typography>
                </Box>
                <Box
                  component="form"
                  onSubmit={handleSubmit(confirmSelection)}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "right", gap: 1, ml: "auto" }}>
                  <Controller
                    name={`projectEmployees.${i}.activeFrom`}
                    defaultValue={employee.activeFrom}
                    rules={{
                      required: isUserSelected(employee.userDto.id),
                      validate: (value) => {
                        if (isUserSelected(employee.userDto.id) && value)
                          return new Date(getValues(`projectEmployees.${i}.activeTo`) as string) > new Date(value as string);
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <DatePicker
                        mask=""
                        label={<FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.activeFrom" />}
                        inputFormat="dd.MM.yyyy"
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => (
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                              {...params}
                              data-testid={`ActiveFromDatePicker-${employee.userDto.accountInfo.username}`}
                              name="activeFrom"
                              sx={{ maxWidth: "175px" }}
                              variant="standard"
                              error={!!error}
                            />
                            <Collapse in={!!error}>
                              <FormHelperText sx={{ fontSize: "9pt" }} error>
                                <FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.activeFrom.error" />
                              </FormHelperText>
                            </Collapse>
                          </Box>
                        )}
                      />
                    )}
                  />
                  <Controller
                    name={`projectEmployees.${i}.activeTo`}
                    defaultValue={employee.activeTo}
                    rules={{
                      required: isUserSelected(getValues(`projectEmployees.${i}.userDto.id`)),
                      validate: (value) => {
                        if (isUserSelected(employee.userDto.id) && value)
                          return new Date(getValues(`projectEmployees.${i}.activeFrom`) as string) < new Date(value as string);
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <DatePicker
                        mask=""
                        label={<FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.activeTo" />}
                        inputFormat="dd.MM.yyyy"
                        onChange={onChange}
                        value={value}
                        renderInput={(params) => (
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                              {...params}
                              data-testid={`ActiveToDatePicker-${employee.userDto.accountInfo.username}`}
                              name="activeTo"
                              role="textbox"
                              sx={{ maxWidth: "175px" }}
                              variant="standard"
                              error={!!error}
                            />
                            <Collapse in={!!error}>
                              <FormHelperText sx={{ fontSize: "9pt" }} error>
                                <FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.activeTo.error" />
                              </FormHelperText>
                            </Collapse>
                          </Box>
                        )}
                      />
                    )}
                  />
                  <Controller
                    name={`projectEmployees.${i}.active`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        data-testid={`ActiveSwitch-${employee.userDto.accountInfo.username}`}
                        sx={{ ml: 1 }}
                        size="small"
                      />
                    )}
                  />
                </Box>
                <Box sx={{ width: "42px" }}>
                  <Checkbox
                    data-testid={`SelectedCheckbox-${employee.userDto.accountInfo.username}`}
                    onChange={() => toggleUserSelection(employee.userDto.id)}
                    checked={isUserSelected(employee.userDto.id)}
                  />
                </Box>
              </Box>
            ))}
          </Stack>
        ) : (
          <Card sx={{ display: "flex", alignItems: "center", flexDirection: "column", p: 4 }}>
            <Inbox color="action" sx={{ mb: 2, width: 100, height: 100, p: 3, backgroundColor: "#e5e5e5", borderRadius: "50%" }} />
            <Typography component="span">
              <FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.emptyState" />
            </Typography>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          <FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.cancelButton" />
        </Button>
        <LoadingButton
          data-testid="ConfirmUpdateButton"
          loading={confirmationLoading}
          onClick={handleSubmit(confirmSelection)}
          startIcon={<Save />}
          color="primary"
          variant="contained">
          <FormattedMessage id="ProjectDetailScreen.projectEmployeeDialog.confirmButton" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditProjectEmployeesModal;
