import { Avatar, Box, IconButton, Menu, MenuItem } from "@mui/material";
import React, { FC, MouseEvent, useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../clients/userClient";

const ProfileMenu: FC = () => {
  const history = useHistory();
  const [cookie, , removeCookie] = useCookies(["userData"]);
  const { userResult } = useCurrentUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event: MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
        color="inherit"
        sx={{ p: 0 }}>
        {userResult.status === "loaded" ? <Avatar src={userResult.payload.accountInfo.picture} /> : null}
      </IconButton>

      <Menu
        aria-label="ProfileOptions"
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}>
        <MenuItem
          role="ProfileBtn"
          aria-label="goToProfile"
          onClick={() => {
            history.push("/profile");
          }}>
          <FormattedMessage id="Navigation.dropdown.account" />
        </MenuItem>
        <MenuItem
          onClick={useCallback(() => {
            if (cookie.userData !== undefined) {
              removeCookie("userData");
            }
            history.push("/login");
          }, [cookie, removeCookie, history])}>
          <FormattedMessage id="Navigation.dropdown.logout" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
