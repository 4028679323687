import { Edit, Save, Delete, Clear } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { Salary } from "../../../../clients/salaryClient";
import { FormattedMessage } from "react-intl";
import { SalaryContext } from "../../../../contexts/SalaryContext";
import theme from "../../../../assets/theming/theme";
import { grey } from "@mui/material/colors";
import { PrivilegeContext } from "../../../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../../../RolesScreen/PrivilegeEnum";

interface EmployeeSalaryRowProps {
  salary: Salary;
}

const EmployeeSalaryRow: FC<EmployeeSalaryRowProps> = ({ salary }: EmployeeSalaryRowProps) => {
  const [currentSalary, setCurrentSalary] = useState(salary);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date(salary.date));
  const [deleteSalaryDialogActive, setDeleteSalaryDialogActive] = useState<boolean>(false);
  const [hasValueChanged, setHasValueChanged] = useState<boolean>(false);
  const { handleSalaryUpdate, submitSalaryDelete, refetchSalaries } = useContext(SalaryContext);
  const { checkForPrivilege } = useContext(PrivilegeContext);

  useEffect(() => {
    setHasValueChanged(JSON.stringify(salary) === JSON.stringify(currentSalary));
  }, [currentSalary, salary]);

  // seperates number every three digits with a period e.g.: 1234567 -> 1.234.567
  const seperateThousands = (num: number) => num.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const handleSalaryRowKeyUp = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
    switch (event.key) {
      case "Enter":
        handleSalaryUpdate({ ...currentSalary, date: date.toISOString() });
        setEditMode(!editMode);
        break;
      case "Escape":
        setCurrentSalary(salary);
        setEditMode(!editMode);
        break;
      default:
        break;
    }
  };

  return (
    <TableRow
      data-testid="EmployeeSalaryRow"
      hover
      selected={editMode}
      sx={{
        "&:last-child td, &:last-child th": { borderBottom: 0 },
        "&:hover [data-testid='SalaryRowEditButton']": { color: theme.palette.primary.main },
        "&:hover [data-testid='SalaryRowDeleteButton']": { color: theme.palette.error.main },
        "&:hover [data-testid='SalaryRowButtonColumn']": { backgroundColor: !editMode ? "#f5f5f5" : "#e5ecf0" },
      }}
      onKeyUp={(event) => handleSalaryRowKeyUp(event)}>
      {/* Datum */}
      <TableCell sx={{ py: 0.5, minWidth: editMode ? 170 : 0 }} align="center">
        {editMode ? (
          <DatePicker
            mask=""
            value={date}
            InputProps={{ style: { fontSize: "14px" } }}
            onChange={(newValue) => {
              if (newValue) {
                setDate(newValue);
              } else {
                setDate(new Date(""));
              }
            }}
            renderInput={(params) => <TextField variant="outlined" {...params} size="small" />}
          />
        ) : (
          <Typography variant="h4">
            {new Date(currentSalary.date).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
          </Typography>
        )}
      </TableCell>
      {/* Korrektur Faktor */}
      <TableCell sx={{ py: 0.5 }} align="center">
        {editMode ? (
          <Checkbox
            checked={currentSalary.correctionFactor}
            onClick={() => setCurrentSalary({ ...currentSalary, correctionFactor: !currentSalary.correctionFactor })}
          />
        ) : (
          <Checkbox disableRipple sx={{ cursor: "default" }} checked={currentSalary.correctionFactor} />
        )}
      </TableCell>
      {/* Bemerkungen */}
      <TableCell sx={{ py: 0.5 }} align="center">
        {editMode ? (
          <TextField
            size="small"
            inputProps={{
              style: { textAlign: "center", fontSize: "14px" },
            }}
            onChange={(event) => {
              setCurrentSalary({ ...currentSalary, notes: event.target.value });
            }}
            value={currentSalary.notes}
          />
        ) : (
          <Typography variant="h4" color={currentSalary.notes ? "" : "lightgray"}>
            {currentSalary.notes ? currentSalary.notes : "\u2571"}
          </Typography>
        )}
      </TableCell>
      {/* Mobilitätsvergütung */}
      <TableCell sx={{ py: 0.5 }} align="center">
        {editMode ? (
          <TextField
            size="small"
            inputProps={{ style: { textAlign: "center", fontSize: "14px" } }}
            onChange={(event) => {
              setCurrentSalary({ ...currentSalary, mobilityAllowance: event.target.value });
            }}
            value={currentSalary.mobilityAllowance}
          />
        ) : (
          <Typography variant="h4" color={currentSalary.mobilityAllowance ? "" : "lightgray"}>
            {currentSalary.mobilityAllowance ? currentSalary.mobilityAllowance : "\u2571"}
          </Typography>
        )}
      </TableCell>
      {/* Jahresgehalt Vollzeit */}
      <TableCell align="right" sx={{ py: 0.5, minWidth: 150 }}>
        {editMode ? (
          <TextField
            size="small"
            sx={{ minWidth: "150px" }}
            inputProps={{
              style: { textAlign: "right", fontSize: "14px" },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            onChange={(e) => {
              !isNaN(parseInt(e.target.value))
                ? setCurrentSalary({
                    ...currentSalary,
                    targetSalary: parseInt(e.target.value),
                    monthlyAmount: ((parseInt(e.target.value) / 100) * currentSalary.partTimeFactor) / 12,
                  })
                : setCurrentSalary({ ...currentSalary, targetSalary: 0, monthlyAmount: 0 });
            }}
            value={currentSalary.targetSalary}
          />
        ) : (
          <Typography variant="h4">{seperateThousands(currentSalary.targetSalary) + " €"}</Typography>
        )}
      </TableCell>
      {/* Jahresgehalt Teilzeit */}
      <TableCell align="right" sx={{ py: 0.5, minWidth: 150 }}>
        {editMode ? (
          <TextField
            size="small"
            sx={{ minWidth: "150px" }}
            inputProps={{
              style: { textAlign: "right", fontSize: "14px" },
              readOnly: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={<FormattedMessage id="EmployeeSalaryInformation.calculated" />} arrow>
                    <Typography variant="overline">A</Typography>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            value={seperateThousands((currentSalary.targetSalary / 100) * currentSalary.partTimeFactor)}
          />
        ) : (
          <Typography variant="h4">
            {seperateThousands((currentSalary.targetSalary / 100) * currentSalary.partTimeFactor) + " €"}
          </Typography>
        )}
      </TableCell>
      {/* Teilzeitfaktor */}
      <TableCell align="right" sx={{ py: 0.5, minWidth: 150 }}>
        {editMode ? (
          <TextField
            size="small"
            inputProps={{
              style: { textAlign: "right", fontSize: "14px" },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={(e) => {
              !isNaN(parseInt(e.target.value)) && parseInt(e.target.value) <= 100
                ? setCurrentSalary({
                    ...currentSalary,
                    partTimeFactor: parseInt(e.target.value),
                    monthlyAmount: ((currentSalary.targetSalary / 100) * parseInt(e.target.value)) / 12,
                  })
                : setCurrentSalary({ ...currentSalary, partTimeFactor: 0, monthlyAmount: 0 });
            }}
            value={currentSalary.partTimeFactor}
          />
        ) : (
          // the check if partTimeFactor is undefined can be removed when BE is fixed
          <Typography variant="h4">{currentSalary.partTimeFactor + " %"}</Typography>
        )}
      </TableCell>
      {/* Monatlicher Betrag */}
      <TableCell sx={{ py: 0.5 }} align="right">
        {editMode ? (
          <TextField
            size="small"
            inputProps={{
              style: { textAlign: "right", fontSize: "14px" },
              readOnly: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={<FormattedMessage id="EmployeeSalaryInformation.calculated" />} arrow>
                    <Typography variant="overline">A</Typography>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            value={seperateThousands(currentSalary.monthlyAmount)}
          />
        ) : (
          <Typography variant="h4"> {seperateThousands(currentSalary.monthlyAmount) + " €"}</Typography>
        )}
      </TableCell>
      {/* Variabler Anteil */}
      <TableCell sx={{ py: 0.5 }} align="right">
        {editMode ? (
          <TextField
            size="small"
            inputProps={{
              style: { textAlign: "right", fontSize: "14px" },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            onChange={(event) => {
              !isNaN(parseInt(event.target.value))
                ? setCurrentSalary({ ...currentSalary, variablePart: parseInt(event.target.value) })
                : setCurrentSalary({ ...currentSalary, variablePart: 0 });
            }}
            value={currentSalary.variablePart}
          />
        ) : (
          <Typography variant="h4"> {seperateThousands(currentSalary.variablePart) + " €"}</Typography>
        )}
      </TableCell>
      {/* Edit & Delete Buttons */}
      <TableCell
        data-testid="SalaryRowButtonColumn"
        sx={{
          py: 0.5,
          position: "sticky",
          right: 0,
          borderLeft: `1px solid ${grey[400]}`,
          backgroundColor: !editMode ? theme.palette.background.default : "#eef3f5",
        }}
        align="center">
        {!editMode ? (
          <Grid item>
            {checkForPrivilege(PrivilegeEnum.CAN_EDIT_SALARY) ? (
              <IconButton aria-label="salaryRowEditButton" data-testid="SalaryRowEditButton" onClick={() => setEditMode(!editMode)}>
                <Edit />
              </IconButton>
            ) : null}
            {checkForPrivilege(PrivilegeEnum.CAN_DELETE_SALARY) ? (
              <IconButton
                onClick={() => setDeleteSalaryDialogActive(true)}
                aria-label="salaryRowDeleteButton"
                data-testid="SalaryRowDeleteButton">
                <Delete />
              </IconButton>
            ) : null}
          </Grid>
        ) : editMode ? (
          <Grid item>
            {checkForPrivilege(PrivilegeEnum.CAN_EDIT_SALARY) ? (
              <>
                <IconButton
                  aria-label="salaryRowSaveButton"
                  color="primary"
                  disabled={hasValueChanged}
                  onClick={() => {
                    handleSalaryUpdate({ ...currentSalary, date: date.toISOString() });
                    setEditMode(false);
                  }}>
                  <Save />
                </IconButton>
                <IconButton
                  aria-label="salaryRowCancelButton"
                  color="error"
                  onClick={() => {
                    setCurrentSalary(salary);
                    setEditMode(false);
                  }}>
                  <Clear />
                </IconButton>
              </>
            ) : null}
          </Grid>
        ) : null}
      </TableCell>
      {deleteSalaryDialogActive ? (
        <Dialog data-testid="DeleteSalaryDialog" open={deleteSalaryDialogActive} onClose={() => setDeleteSalaryDialogActive(false)}>
          <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FormattedMessage id="EmployeeSalaryInformation.deleteTitle" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="EmployeeSalaryInformation.deletePrompt" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="SalaryDeleteCancelButton" onClick={() => setDeleteSalaryDialogActive(false)}>
              <FormattedMessage id="EmployeeSalaryInformation.deleteCancel" />
            </Button>
            <Button
              data-testid="SalaryDeleteConfirmButton"
              variant="contained"
              color="error"
              onClick={() => {
                submitSalaryDelete(salary.id);
                setTimeout(() => {
                  refetchSalaries();
                }, 500);
                setDeleteSalaryDialogActive(false);
              }}>
              <FormattedMessage id="EmployeeSalaryInformation.deleteConfirm" />
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </TableRow>
  );
};
export default EmployeeSalaryRow;
