import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  Checkbox,
  TextField,
  Typography,
  RadioGroup,
} from "@mui/material";
import { Person, Home, AssignmentInd, Apartment, Edit, Cancel, Save } from "@mui/icons-material";
import React, { FC, useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { User } from "../../../clients/userClient";
import { Controller, useForm } from "react-hook-form";
import theme from "../../../assets/theming/theme";
import { DatePicker } from "@mui/lab";
import { UserContext } from "../../../contexts/UserContext";
import { PrivilegeContext } from "../../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../../RolesScreen/PrivilegeEnum";
import { useUtils } from "../../../utils/Utils";

interface EmployeePersonalDataProps {
  user: User;
}

const EmployeePersonalData: FC<EmployeePersonalDataProps> = ({ user }: EmployeePersonalDataProps) => {
  const { updateUser, userResult } = useContext(UserContext);
  const { regexPatterns } = useUtils();
  const { checkForPrivilege } = useContext(PrivilegeContext);
  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<User>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: user,
  });
  const [editMode, setEditMode] = useState(false);

  const handleUpdateUser = (data: User) => {
    setEditMode(false);
    updateUser(data);
  };

  useEffect(() => {
    if (userResult.status === "loaded") {
      reset(userResult.payload);
    }
    if (userResult.status === "error") {
      reset(user);
    }
  }, [userResult, reset, user]);

  const getLocaleString = (date: string): string | null => {
    if (!date) return null;
    return new Date(date).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" });
  };

  const handleCancelUpdate = () => {
    setEditMode(false);
    reset(user);
  };

  const setNullOrValue = (value: string) => {
    if (value === "") return null;
    else return value;
  };

  useEffect(() => {
    // reset also inits form inputs on render
    reset(user);
  }, [reset, user]);

  return (
    <Box
      component="form"
      onSubmit={() => {
        handleSubmit((data) => handleUpdateUser(data));
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h5">Mitarbeiterinformationen</Typography>
        {checkForPrivilege(PrivilegeEnum.CAN_EDIT_USER) ? (
          <>
            {!editMode ? (
              <IconButton sx={{ "&:hover": { color: theme.palette.primary.main } }} onClick={() => setEditMode(true)}>
                <Edit />
              </IconButton>
            ) : (
              <Box>
                <IconButton color="primary" onClick={handleSubmit(handleUpdateUser)}>
                  <Save />
                </IconButton>
                <IconButton color="error" onClick={() => handleCancelUpdate()}>
                  <Cancel />
                </IconButton>
              </Box>
            )}
          </>
        ) : null}
      </Box>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="overline"
                  sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                  <Person />
                  <FormattedMessage id="EmployeeScreen.createUserDialog.personalInformation.title" />
                </Typography>
                {editMode ? (
                  <Typography variant="caption" color="error" sx={{ ml: 1, fontStyle: "italic", fontSize: "0.75rem" }}>
                    <FormattedMessage id="EmployeeScreen.createUserDialog.personalInformation.requiredFields" />
                  </Typography>
                ) : null}
              </Box>
              <Grid container spacing={2}>
                <Grid item md={4.5}>
                  <TextField
                    {...register("firstName", { required: true })}
                    required
                    disabled={!editMode}
                    error={!!errors.firstName}
                    fullWidth
                    data-testid="FirstNameTextField"
                    variant="standard"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    name="firstName"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.firstName" />}
                  />
                  <Collapse in={!!errors.firstName}>
                    <FormHelperText sx={{ fontSize: "9pt" }} error>
                      <FormattedMessage id="EmployeeScreen.createUserDialog.label.firstName.error" />
                    </FormHelperText>
                  </Collapse>
                </Grid>
                <Grid item md={4.5}>
                  <TextField
                    {...register("lastName", { required: true })}
                    required
                    disabled={!editMode}
                    error={!!errors.lastName}
                    data-testid="LastNameTextField"
                    fullWidth
                    variant="standard"
                    name="lastName"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.lastName" />}
                  />
                  <Collapse in={!!errors.lastName}>
                    <FormHelperText focused sx={{ fontSize: "9pt" }} error>
                      <FormattedMessage id="EmployeeScreen.createUserDialog.label.lastName.error" />
                    </FormHelperText>
                  </Collapse>
                </Grid>
                <Grid item md={3}>
                  <TextField
                    {...register("employeeId", { setValueAs: setNullOrValue, pattern: regexPatterns.employeeId })}
                    disabled={!editMode}
                    data-testid="EmployeeIdTextField"
                    error={!!errors.employeeId}
                    fullWidth
                    variant="standard"
                    name="employeeId"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.employeeId" />}
                  />
                  <Collapse in={!!errors.employeeId}>
                    <FormHelperText focused sx={{ fontSize: "9pt" }} error>
                      <FormattedMessage id="EmployeeScreen.createUserDialog.label.employeeId.error" />
                    </FormHelperText>
                  </Collapse>
                </Grid>
                <Grid item md={4}>
                  {!editMode ? (
                    <TextField
                      disabled={!editMode}
                      value={getLocaleString(getValues("dayOfBirth") as string)}
                      fullWidth
                      variant="standard"
                      data-testid="DayOfBirthTextField"
                      name="dayOfBirth"
                      label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.dayOfBirth" />}
                    />
                  ) : (
                    <Controller
                      name="dayOfBirth"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.dayOfBirth" />}
                          inputFormat="dd.MM.yyyy"
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField data-testid="DayOfBirthDatePicker" name="dayOfBirth" fullWidth variant="standard" {...params} />
                          )}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item md={8}>
                  <TextField
                    {...register("highestDegree")}
                    disabled={!editMode}
                    fullWidth
                    variant="standard"
                    data-testid="HighestDegreeTextField"
                    name="highestDegree"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.highestDegree" />}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("nationality")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="NationalityTextField"
                    variant="standard"
                    name="nationality"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.nationality" />}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("religion")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="ReligionTextField"
                    variant="standard"
                    name="religion"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.religion" />}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("taxOffice")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="TaxOfficeTextField"
                    variant="standard"
                    name="taxOffice"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.taxOffice" />}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("taxIdentificationNumber", { setValueAs: setNullOrValue, pattern: regexPatterns.taxId })}
                    disabled={!editMode}
                    fullWidth
                    error={!!errors.taxIdentificationNumber}
                    data-testid="TaxIdTextField"
                    variant="standard"
                    name="taxIdentificationNumber"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.taxIdentificationNumber" />}
                  />
                  <Collapse in={!!errors.taxIdentificationNumber}>
                    <Stack>
                      <FormHelperText focused sx={{ fontSize: "9pt" }} error>
                        <FormattedMessage id="EmployeeScreen.createUserDialog.label.taxIdentificationNumber.error" />
                      </FormHelperText>
                      <FormHelperText focused sx={{ fontSize: "9pt", fontStyle: "italic" }} error>
                        <FormattedMessage id="EmployeeScreen.createUserDialog.label.taxIdentificationNumber.error-line2" />
                      </FormHelperText>
                    </Stack>
                  </Collapse>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("healthInsurance")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="HealthInsuranceTextField"
                    variant="standard"
                    name="healthInsurance"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.healthInsurance" />}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("socialSecurityNumber", { setValueAs: setNullOrValue, pattern: regexPatterns.socialSecurityNumber })}
                    disabled={!editMode}
                    error={!!errors.socialSecurityNumber}
                    fullWidth
                    data-testid="SocialSecurityTextField"
                    variant="standard"
                    name="socialSecurityNumber"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.socialSecurityNumber" />}
                  />
                  <Collapse in={!!errors.socialSecurityNumber}>
                    <Stack>
                      <FormHelperText focused sx={{ fontSize: "9pt" }} error>
                        <FormattedMessage id="EmployeeScreen.createUserDialog.label.socialSecurityNumber.error" />
                      </FormHelperText>
                      <FormHelperText focused sx={{ fontSize: "9pt", fontStyle: "italic" }} error>
                        <FormattedMessage id="EmployeeScreen.createUserDialog.label.socialSecurityNumber.error-line2" />
                      </FormHelperText>
                    </Stack>
                  </Collapse>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    {...register("iban", { setValueAs: setNullOrValue, pattern: regexPatterns.iban })}
                    disabled={!editMode}
                    fullWidth
                    data-testid="IbanTextField"
                    variant="standard"
                    name="iban"
                    error={!!errors.iban}
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.iban" />}
                  />
                  <Collapse in={!!errors.iban}>
                    <Stack>
                      <FormHelperText sx={{ fontSize: "9pt" }} error>
                        <FormattedMessage id="EmployeeScreen.createUserDialog.label.iban.error" />
                      </FormHelperText>
                      <FormHelperText sx={{ fontSize: "9pt", fontStyle: "italic" }} error>
                        <FormattedMessage id="EmployeeScreen.createUserDialog.label.iban.error-line2" />
                      </FormHelperText>
                    </Stack>
                  </Collapse>
                </Grid>
                <Grid item md={4}>
                  <FormControl fullWidth>
                    <InputLabel sx={{ left: -14, bottom: -4 }} id="taxClassSelectLabel">
                      <FormattedMessage id="EmployeeScreen.createUserDialog.label.taxClass" />
                    </InputLabel>
                    <Controller
                      name="taxClass"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          disabled={!editMode}
                          name="taxClass"
                          labelId="taxClassSelectLabel"
                          variant="standard"
                          data-testid="TaxClassSelect">
                          {[...Array(6)].map((e, i) => (
                            <MenuItem key={i} value={i + 1} data-testid={`TaxClassOption-${i + 1}`}>
                              <FormattedMessage id="EmployeeScreen.createUserDialog.label.taxClass" /> {i + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={5.5}>
          <Card>
            <CardContent>
              <Typography
                variant="overline"
                sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                <Home />
                <FormattedMessage id="EmployeeScreen.createUserDialog.address.title" />
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={9}>
                  <TextField
                    {...register("street")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="StreetTextField"
                    variant="standard"
                    name="street"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.street" />}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                    {...register("houseNumber")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="HouseNrTextField"
                    variant="standard"
                    name="houseNumber"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.houseNumber" />}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    {...register("zipCode")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="ZipCodeTextField"
                    variant="standard"
                    name="zipCode"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.zipCode" />}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]{0,5}" }}
                  />
                </Grid>
                <Grid item md={8}>
                  <TextField
                    {...register("city")}
                    disabled={!editMode}
                    fullWidth
                    data-testid="CityTextField"
                    variant="standard"
                    name="city"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.city" />}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6.5}>
          <Card>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="overline"
                  sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                  <AssignmentInd sx={{ mb: 0.5 }} />
                  <FormattedMessage id="EmployeeScreen.createUserDialog.contactInformation.title" />
                </Typography>
                {editMode ? (
                  <Typography variant="caption" color="error" sx={{ ml: 1, fontStyle: "italic", fontSize: "0.75rem" }}>
                    <FormattedMessage id="EmployeeScreen.createUserDialog.personalInformation.requiredFields" />
                  </Typography>
                ) : null}
              </Box>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="accountInfo.email"
                    control={control}
                    render={({ field: { value } }) => (
                      <TextField
                        disabled={true}
                        value={value}
                        fullWidth
                        type="email"
                        variant="standard"
                        data-testid="EmailTextField"
                        name="email"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.email" />}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="privateEmail"
                    rules={{
                      pattern: regexPatterns.email,
                    }}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        disabled={!editMode}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        fullWidth
                        type="email"
                        variant="standard"
                        data-testid="EmailTextField"
                        name="privateEmail"
                        label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.privateEmail" />}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("phonePrivate", { setValueAs: setNullOrValue })}
                    disabled={!editMode}
                    fullWidth
                    data-testid="PhronePrivateTextField"
                    variant="standard"
                    name="phonePrivate"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.phonePrivate" />}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    {...register("phoneWork", { setValueAs: setNullOrValue })}
                    disabled={!editMode}
                    fullWidth
                    data-testid="PhoneWorkTextField"
                    variant="standard"
                    name="phoneWork"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.phoneWork" />}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Typography
                variant="overline"
                sx={{ color: theme.palette.primary.main, display: "flex", alignItems: "center", gap: "0.75rem" }}>
                <Apartment />
                <FormattedMessage id="EmployeeScreen.createUserDialog.businessInformation.title" />
              </Typography>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl>
                    <FormLabel id="partOrFullTimeLabel" sx={{ fontSize: "0.75rem" }}>
                      <FormattedMessage id="EmployeeScreen.createUserDialog.label.partOrFullTime" />
                    </FormLabel>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        pl: 1.75,
                        borderRadius: 1,
                      }}>
                      <Controller
                        name="partOrFullTime"
                        control={control}
                        defaultValue="fullTime"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <RadioGroup
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            name="partOrFullTime"
                            data-testid="PartFullTimeTextField"
                            aria-labelledby="partOrFullTimeLabel"
                            row>
                            <FormControlLabel
                              value="fullTime"
                              label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.partOrFullTime.fullTime" />}
                              control={<Radio disabled={!editMode} data-testid="FullTimeRadio" size="small" />}
                            />
                            <FormControlLabel
                              value="partTime"
                              label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.partOrFullTime.partTime" />}
                              control={<Radio disabled={!editMode} data-testid="PartTimeRadio" size="small" />}
                            />
                          </RadioGroup>
                        )}
                      />
                      <Divider variant="middle" orientation="vertical" flexItem />
                      <Controller
                        name="probation"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            sx={{ ml: 1 }}
                            label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.probation" />}
                            control={<Checkbox checked={value} onChange={onChange} disabled={!editMode} data-testid="ProbationCheckbox" />}
                          />
                        )}
                      />
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item md={6}></Grid>
                <Grid item md={8}>
                  <TextField
                    {...register("jobTitle")}
                    disabled={!editMode}
                    data-testid="JobTitleTextField"
                    fullWidth
                    variant="standard"
                    name="jobTitle"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.jobTitle" />}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    {...register("childAllowance")}
                    disabled={!editMode}
                    fullWidth
                    variant="standard"
                    data-testid="ChildAllowanceTextField"
                    name="childAllowance"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.childAllowance" />}
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  {!editMode ? (
                    <TextField
                      disabled={!editMode}
                      value={getLocaleString(getValues("entryDate") as string)}
                      fullWidth
                      variant="standard"
                      data-testid="EntryDateTextField"
                      name="entryDate"
                      label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.entryDate" />}
                    />
                  ) : (
                    <Controller
                      name="entryDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.entryDate" />}
                          inputFormat="dd.MM.yyyy"
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField data-testid="EntryDatePicker" name="entryDate" fullWidth variant="standard" {...params} />
                          )}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item md={4}>
                  {!editMode ? (
                    <TextField
                      disabled={!editMode}
                      value={getLocaleString(getValues("resignationDate") as string)}
                      fullWidth
                      variant="standard"
                      data-testid="ResignationTextField"
                      name="resignationDate"
                      label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.resignationDate" />}
                    />
                  ) : (
                    <Controller
                      name="resignationDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.resignationDate" />}
                          inputFormat="dd.MM.yyyy"
                          value={value}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField
                              data-testid="ResignationDatePicker"
                              name="resignationDate"
                              fullWidth
                              variant="standard"
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item md={4}>
                  <TextField
                    {...register("vacationDays")}
                    fullWidth
                    data-testid="VacationDaysTextField"
                    variant="standard"
                    name="vacationDays"
                    label={<FormattedMessage id="EmployeeScreen.createUserDialog.label.vacationDays" />}
                    type="number"
                    disabled={!editMode}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*,+[0-9]{0, 2}" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{parseInt(getValues("vacationDays") + "") === 1 ? "Tag" : "Tage"}</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeePersonalData;
