import { Badge, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { RouteInfo, routesNavBar } from "../../App";
import { Role } from "../../clients/roleClient";
import RoutesMenu from "./RoutesMenu";
import ProfileMenu from "./ProfileMenu";
import logo from "../../assets/images/moguru_logo_white_150@2x.png";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";
import { useContext } from "react";
export class InvalidTokenError extends Error {}

export interface JwtPayload {
  type?: string;
  exp?: number;
  roles: Role[];
}

const Navigation = () => {
  const history = useHistory();
  const { checkForPrivilege } = useContext(PrivilegeContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ top: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }} color={"primary"}>
        <Toolbar>
          <Box sx={{ flexGrow: 0, mr: 2, display: { xs: "none", md: "flex" } }}>
            <img src={logo} alt="logo" height={40} />
          </Box>
          <Box sx={{ flexGrow: 1, mr: 2, display: { xs: "flex", md: "none" } }}>
            <RoutesMenu />
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={logo} alt="logo" height={40} />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {routesNavBar.map((route: RouteInfo) =>
              route.privilege === undefined || (route.privilege && checkForPrivilege(route.privilege)) ? (
                <Button key={route.title} color="inherit" onClick={() => history.push(route.path)}>
                  <Badge sx={{ mr: 0.75 }}>{route.drawerIcon}</Badge>
                  <FormattedMessage id={route.title} />
                </Button>
              ) : null,
            )}
          </Box>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navigation;
