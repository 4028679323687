import { Delete, Key } from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Switch,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { FC, ReactElement, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Privilege, useRole } from "../../clients/roleClient";
import { Role } from "../../clients/roleClient";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";
import { RolesContext } from "../../contexts/RoleContext";
import EditPrivileges from "./EditPrivileges";
import { PrivilegeEnum } from "./PrivilegeEnum";

const RolesScreenRow: FC<{
  role: Role;
  refreshAllRoles: () => void;
  privileges: Privilege[];
}> = ({ role, privileges }): ReactElement => {
  const { roleResult: result } = useRole();
  const { submitRoleDelete, handleRoleUpdate } = useContext(RolesContext);
  const { checkForPrivilege } = useContext(PrivilegeContext);
  const [deleteDialogActive, setDeleteDialogActive] = useState<boolean>(false);
  const [editPrivilegesDialogActive, setEditPrivilegesDialogActive] = useState<boolean>(false);

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}>
        <TableCell>{role.name}</TableCell>
        <TableCell>
          <Chip key={role.name} label={role.privileges?.length + " Berechtigungen"} role="Chip" aria-label="privilegesChip" />
          {checkForPrivilege(PrivilegeEnum.CAN_EDIT_ROLE) ? (
            <IconButton
              aria-label="edit-privileges"
              disabled={role.id === process.env.REACT_APP_ADMIN_ID || result.status === "loading"}
              onClick={() => setEditPrivilegesDialogActive(true)}>
              <Key />
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell align="right">
          {checkForPrivilege(PrivilegeEnum.CAN_EDIT_ROLE) ? (
            <Switch
              disabled={role.id === process.env.REACT_APP_ADMIN_ID || result.status === "loading"}
              onChange={() => {
                const newRole = { ...role, active: !role.active };
                handleRoleUpdate(newRole);
              }}
              checked={role.active}
              sx={{ mr: 1 }}
            />
          ) : null}
          {checkForPrivilege(PrivilegeEnum.CAN_DELETE_ROLE) ? (
            <IconButton
              disabled={role.id === process.env.REACT_APP_ADMIN_ID || result.status === "loading"}
              color="error"
              onClick={() => setDeleteDialogActive(true)}>
              <Delete />
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      {editPrivilegesDialogActive ? (
        <EditPrivileges
          open={editPrivilegesDialogActive}
          onClose={() => setEditPrivilegesDialogActive(false)}
          onSubmit={(newPrivileges: Privilege[]) => {
            handleRoleUpdate({ ...role, privileges: newPrivileges });
          }}
          allPrivileges={privileges}
          rolePrivileges={role.privileges}
        />
      ) : null}
      {deleteDialogActive ? (
        <Dialog open={deleteDialogActive} onClose={() => setDeleteDialogActive(false)}>
          <DialogTitle>
            <FormattedMessage id="RolesScreen.delete" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="RolesScreen.deletePrompt" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogActive(false)}>
              <FormattedMessage id="RolesScreen.deleteCancel" />
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                submitRoleDelete(role.id);
                setTimeout(() => {
                  setDeleteDialogActive(false);
                  window.location.reload();
                }, 1000);
              }}>
              <FormattedMessage id="RolesScreen.deleteConfirm" />
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default RolesScreenRow;
