import { Badge, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import React, { FC, MouseEvent, useContext, useState } from "react";
import { RouteInfo, routesNavBar } from "../../App";
import { PrivilegeContext } from "../../contexts/PrivilegeContext";

const RoutesMenu: FC = () => {
  const history = useHistory();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { checkForPrivilege } = useContext(PrivilegeContext);

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event: MouseEvent<HTMLElement>) => {
          setAnchorElNav(event.currentTarget);
        }}
        color="inherit">
        <MenuIcon />
      </IconButton>
      <Menu
        aria-label="openMenuItems"
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={() => {
          setAnchorElNav(null);
        }}
        sx={{
          display: { xs: "block", md: "none" },
        }}>
        {routesNavBar.map((route: RouteInfo) =>
          route.privilege === undefined || checkForPrivilege(route.privilege) ? (
            <MenuItem key={route.title} aria-label="goToUsersScreen" onClick={() => history.push(route.path)}>
              <Badge sx={{ mr: 0.75 }}>{route.drawerIcon}</Badge>
              <FormattedMessage id={route.title} />
            </MenuItem>
          ) : null,
        )}
      </Menu>
    </>
  );
};

export default RoutesMenu;
