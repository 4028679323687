import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { DoneAll, Engineering, Pause, Help, Timelapse } from "@mui/icons-material";

export const useUtils = (): {
  regexPatterns: {
    email: RegExp;
    employeeId: RegExp;
    socialSecurityNumber: RegExp;
    taxId: RegExp;
    iban: RegExp;
  };
} => {
  const regexPatterns = {
    email:
      /[a-z0-9!#$%&'*+\/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    employeeId: /^M\d{4}$/,
    socialSecurityNumber: /^\d{8}?[a-zA-Z]{1}?\d{3}$/,
    taxId: /^\d{11}?$/,
    iban: /^[A-Z]{2}?\d{20}?$/,
  };

  return {
    regexPatterns,
  };
};

export const useProjectUtils = (): {
  getProjectStatusColor: () => "primary" | "warning" | "secondary" | "success" | "default";
  // eslint-disable-next-line
  getProjectStatusIcon: () => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
} => {
  const { currentProject } = useContext(ProjectContext);

  const getProjectStatusColor = (): "primary" | "warning" | "secondary" | "success" | "default" => {
    switch (currentProject.status) {
      case "DEVELOPMENT":
        return "primary";
      case "MAINTENANCE":
        return "warning";
      case "PAUSED":
        return "secondary";
      case "FINISHED":
        return "success";
      default:
        return "default";
    }
  };

  const getProjectStatusIcon = () => {
    switch (currentProject.status) {
      case "DEVELOPMENT":
        return <Timelapse sx={{ display: "flex" }} fontSize="small" />;
      case "MAINTENANCE":
        return <Engineering sx={{ display: "flex" }} fontSize="small" />;
      case "PAUSED":
        return <Pause sx={{ display: "flex" }} fontSize="small" />;
      case "FINISHED":
        return <DoneAll sx={{ display: "flex" }} fontSize="small" />;
      default:
        return <Help sx={{ display: "flex" }} fontSize="small" />;
    }
  };

  return {
    getProjectStatusColor,
    getProjectStatusIcon,
  };
};
