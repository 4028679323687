import { createContext, useEffect, useState } from "react";
import { Privilege, Role } from "../clients/roleClient";
import { AccountInfo } from "../clients/userClient";
import { PrivilegeEnum } from "../components/RolesScreen/PrivilegeEnum";

interface PrivilegeType {
  checkForPrivilege: (privilege: PrivilegeEnum) => boolean;
}
export const PrivilegeContext = createContext({} as PrivilegeType);

const PrivilegeProvider: React.FC = ({ children }) => {
  const [privileges, setPrivileges] = useState<Privilege[]>([]);

  const userStorage: AccountInfo = JSON.parse(localStorage.getItem("accountInfo") || JSON.stringify({ username: "", roles: [] }));
  const isAdmin: boolean = JSON.parse(localStorage.getItem("isAdmin") || JSON.stringify(false));

  const checkForPrivilege = (privilegeEnum: PrivilegeEnum) => {
    if (isAdmin) {
      return true;
    }
    return privileges.some((privilege: Privilege) => privilege.privilegeKey === privilegeEnum);
  };

  useEffect(() => {
    const privileges: Privilege[] = [];
    userStorage.roles.map((role: Role) =>
      role.privileges?.map((privilege: Privilege) => {
        if (!privileges.some((arrPrivilege) => arrPrivilege === privilege)) privileges.push(privilege);
      }),
    );
    setPrivileges(privileges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    checkForPrivilege,
  };

  return <PrivilegeContext.Provider value={data}>{children}</PrivilegeContext.Provider>;
};

export default PrivilegeProvider;
