import {
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  IconButton,
  Checkbox,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { IMaskInput as InputMaskInput } from "react-imask";
import { useParams } from "react-router";
import { Add, Send, Clear, Inbox } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { grey } from "@mui/material/colors";
import { Salary } from "../../../../clients/salaryClient";
import React, { FC, useState, useContext } from "react";
import EmployeeSalaryRow from "./EmployeeSalaryRow";
import { SalaryContext } from "../../../../contexts/SalaryContext";
import { PrivilegeContext } from "../../../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../../../../components/RolesScreen/PrivilegeEnum";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// eslint-disable-next-line
const CurrencyTextField = React.forwardRef<HTMLElement, CustomProps>(function CurrencyTextField(props: any, ref: any) {
  const { onChange, name, ...other } = props;
  return (
    <InputMaskInput
      {...other}
      mask={Number}
      padFractionalZeros
      thousandsSeparator="."
      radix=","
      inputRef={ref}
      // eslint-disable-next-line
      onAccept={(value: any) => onChange({ target: { name, value } })}
      overwrite
      unmask="typed"
    />
  );
});

const EmployeeSalaryTable: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const initialSalary = {
    id: "",
    active: false,
    date: new Date().toString(),
    correctionFactor: false,
    targetSalary: 0,
    variablePart: 0,
    partTimeFactor: 100,
    mobilityAllowance: "",
    monthlyAmount: 0,
    notes: "",
  };
  const [currentSalary, setCurrentSalary] = useState<Salary>(initialSalary);
  const [createSalaryFormActive, setCreateSalaryFormActive] = useState<boolean>(false);
  const { salaries, createSalary } = useContext(SalaryContext);
  const { checkForPrivilege } = useContext(PrivilegeContext);

  const tableHeaders = [
    "EmployeeSalaryInformation.date",
    "EmployeeSalaryInformation.correctionFactor",
    "EmployeeSalaryInformation.notes",
    "EmployeeSalaryInformation.mobilityAllowance",
    "EmployeeSalaryInformation.targetFullTimeSalary",
    "EmployeeSalaryInformation.targetPartTimeSalary",
    "EmployeeSalaryInformation.partTimeFactor",
    "EmployeeSalaryInformation.monthlyAmount",
    "EmployeeSalaryInformation.variablePart",
  ];

  const confirmCreateSalary = () => {
    createSalary(userId, currentSalary);
    setCreateSalaryFormActive(false);
    setCurrentSalary(initialSalary);
  };

  const abortCreateSalary = () => {
    setCreateSalaryFormActive(false);
    setCurrentSalary(initialSalary);
  };

  // seperates number every three digits with a period, also adds padding zeros e.g.: 1234567 -> 1.234.567,00
  const seperateThousands = (num: number) => num.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const handleSalaryRowKeyUp = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (createSalaryFormActive) {
      switch (event.key) {
        case "Enter":
          confirmCreateSalary();
          break;
        case "Escape":
          abortCreateSalary();
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: 310, overflow: "auto", maxWidth: "1120px" }} component={Card}>
        <Table sx={{ minWidth: 650 }} size="medium" stickyHeader>
          <TableHead style={{ padding: 0 }}>
            <TableRow
              style={{
                whiteSpace: "nowrap",
              }}>
              {tableHeaders.map((tableHeader) => (
                <TableCell align="center" key={tableHeader} sx={{ backgroundColor: grey[300] }}>
                  <Typography variant="h5">
                    <FormattedMessage id={tableHeader} />
                  </Typography>
                </TableCell>
              ))}
              <TableCell
                align="center"
                colSpan={8}
                sx={{ py: 0, px: 2, backgroundColor: grey[300], position: "sticky", right: 0, borderLeft: `1px solid ${grey[400]}` }}>
                {checkForPrivilege(PrivilegeEnum.CAN_CREATE_SALARY) ? (
                  <Button
                    disabled={createSalaryFormActive}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreateSalaryFormActive(true)}
                    startIcon={<Add />}>
                    <FormattedMessage id="EmployeeSalaryInformation.create" />
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {createSalaryFormActive ? (
              <TableRow
                selected
                onKeyUp={(event) => handleSalaryRowKeyUp(event)}
                data-testid="salaryCreateTableRow"
                sx={{
                  "&:hover [data-testid='SalaryConfirmColumn']": { backgroundColor: "#e5ecf0" },
                  position: "sticky",
                  top: 0,
                }}>
                {/* Datum */}
                <TableCell sx={{ py: 0.5, minWidth: 170 }} align="center">
                  <DatePicker
                    mask=""
                    value={currentSalary.date}
                    InputProps={{ style: { fontSize: "14px" } }}
                    onChange={(newValue) => {
                      newValue ? setCurrentSalary({ ...currentSalary, date: newValue }) : new Date().toString();
                    }}
                    renderInput={(params) => <TextField data-testid="salaryCreateDatePicker" variant="outlined" size="small" {...params} />}
                  />
                </TableCell>
                {/* Korrekturfaktor */}
                <TableCell sx={{ py: 0.5 }} align="center">
                  <Checkbox
                    data-testid="salaryCreateCorrectionFactorCheckbox"
                    checked={currentSalary.correctionFactor}
                    onClick={() => setCurrentSalary({ ...currentSalary, correctionFactor: !currentSalary.correctionFactor })}
                  />
                </TableCell>
                {/* Bemerkungen */}
                <TableCell sx={{ py: 0.5 }} align="center">
                  <TextField
                    data-testid="salaryCreateNotesInput"
                    inputProps={{
                      inputMode: "numeric",
                      style: { fontSize: "14px" },
                    }}
                    sx={{ minWidth: "200px" }}
                    onChange={(e) => {
                      setCurrentSalary({ ...currentSalary, notes: e.target.value });
                    }}
                    value={currentSalary.notes}
                    size="small"
                  />
                </TableCell>
                {/* Mobilitätsvergütung */}
                <TableCell sx={{ py: 0.5 }} align="center">
                  <TextField
                    data-testid="salaryCreateMobilityAllowanceInput"
                    onChange={(e) => {
                      setCurrentSalary({ ...currentSalary, mobilityAllowance: e.target.value });
                    }}
                    value={currentSalary.mobilityAllowance}
                    size="small"
                  />
                </TableCell>
                {/* Jahresgehalt Vollzeit */}
                <TableCell sx={{ py: 0.5 }}>
                  <TextField
                    data-testid="salaryCreateTargetFullTimeSalaryInput"
                    onChange={({ target: { value } }) => {
                      setCurrentSalary({
                        ...currentSalary,
                        targetSalary: parseInt(value) ?? 0,
                        monthlyAmount: currentSalary.partTimeFactor ? ((parseInt(value) / 100) * currentSalary.partTimeFactor) / 12 : 0,
                      });
                    }}
                    value={currentSalary.targetSalary !== 0 ? currentSalary.targetSalary : null}
                    size="small"
                    sx={{ minWidth: "150px" }}
                    inputProps={{
                      style: { textAlign: "right", fontSize: "14px" },
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      // eslint-disable-next-line
                      inputComponent: CurrencyTextField as any,
                    }}
                  />
                </TableCell>
                {/* Jahresgehalt Teilzeit */}
                <TableCell sx={{ py: 0.5 }}>
                  <TextField
                    data-testid="salaryCreateTargetPartTimeSalaryInput"
                    value={seperateThousands((currentSalary.targetSalary / 100) * currentSalary.partTimeFactor)}
                    size="small"
                    inputProps={{
                      style: { textAlign: "right", fontSize: "14px" },
                      readOnly: true,
                    }}
                    sx={{ minWidth: "150px" }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip title={<FormattedMessage id="EmployeeSalaryInformation.calculated" />} arrow>
                            <Typography variant="overline">A</Typography>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                {/* Teilzeitfaktor */}
                <TableCell sx={{ py: 0.5 }}>
                  <TextField
                    data-testid="salaryCreatePartTimeFactorInput"
                    onChange={(e) => {
                      /\d*,?\d{0,2}/.test(e.target.value) && parseInt(e.target.value) <= 100
                        ? setCurrentSalary({
                            ...currentSalary,
                            partTimeFactor: parseInt(e.target.value),
                            monthlyAmount: ((currentSalary.targetSalary / 100) * parseInt(e.target.value)) / 12,
                          })
                        : setCurrentSalary({ ...currentSalary, partTimeFactor: 0, monthlyAmount: 0 });
                    }}
                    value={currentSalary.partTimeFactor}
                    size="small"
                    inputProps={{
                      style: { textAlign: "right", fontSize: "14px" },
                    }}
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  />
                </TableCell>
                {/* Monatlicher Betrag */}
                <TableCell sx={{ py: 0.5 }}>
                  <TextField
                    data-testid="salaryCreateMonthlyAmountInput"
                    value={seperateThousands(currentSalary.monthlyAmount)}
                    size="small"
                    sx={{ minWidth: "150px" }}
                    inputProps={{
                      style: { textAlign: "right", fontSize: "14px" },
                      readOnly: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip title={<FormattedMessage id="EmployeeSalaryInformation.calculated" />} arrow>
                            <Typography variant="overline">A</Typography>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                {/* Variabler Anteil */}
                <TableCell sx={{ py: 0.5 }}>
                  <TextField
                    data-testid="salaryCreateVariablePartInput"
                    onChange={(e) => {
                      !isNaN(parseInt(e.target.value))
                        ? setCurrentSalary({ ...currentSalary, variablePart: parseInt(e.target.value) })
                        : setCurrentSalary({ ...currentSalary, variablePart: 0 });
                    }}
                    sx={{ minWidth: "150px" }}
                    value={currentSalary.variablePart}
                    size="small"
                    inputProps={{
                      style: { textAlign: "right", fontSize: "14px" },
                    }}
                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                  />
                </TableCell>
                {/* Confirm & Cancel Button */}
                <TableCell
                  sx={{ py: 0.5, position: "sticky", right: 0, backgroundColor: "#eef3f5", borderLeft: `1px solid ${grey[400]}` }}
                  data-testid="SalaryConfirmColumn"
                  align="center">
                  <IconButton data-testid="salaryCreateConfirmButton" color="primary" onClick={() => confirmCreateSalary()}>
                    <Send />
                  </IconButton>
                  <IconButton data-testid="salaryCreateCancelButton" color="error" onClick={() => abortCreateSalary()}>
                    <Clear />
                  </IconButton>
                </TableCell>
              </TableRow>
            ) : null}
            {salaries
              .sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))
              .map((salaryItem) => (
                <EmployeeSalaryRow key={salaryItem.id} salary={salaryItem} />
              ))}
            {salaries.length <= 0 ? (
              <TableRow data-testid="EmptyStateRow">
                <TableCell align="center" colSpan={8} sx={{ position: "sticky", left: 0 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Inbox color="action" sx={{ mb: 2, width: 128, height: 128, p: 3, backgroundColor: "#e5e5e5", borderRadius: "50%" }} />
                    <Typography color="action" variant="subtitle1">
                      <FormattedMessage id="EmployeeSalaryInformation.emptyState" />
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EmployeeSalaryTable;
