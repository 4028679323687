import { useCallback, useEffect } from "react";
import { PrivilegeEnum } from "../components/RolesScreen/PrivilegeEnum";
import { Result } from "./useFetch";
import { useFetch } from "./useFetch";

export interface Role {
  id: string;
  name: string;
  active: boolean;
  privileges: Privilege[];
}

export interface Privilege {
  privilegeKey: PrivilegeEnum;
  value: string;
  description: string;
}

export const useAllRoles = (): {
  allRolesResult: Result<Role[]>;
  refreshAllRoles: () => void;
} => {
  const { fetcher: rolesFetcher, result: allRolesResult } = useFetch<Role[]>();

  const getRoles = useCallback(() => {
    rolesFetcher(process.env.REACT_APP_SERVER + "/role");
  }, [rolesFetcher]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return {
    allRolesResult,
    refreshAllRoles: getRoles,
  };
};

export const useRole = (): {
  roleResult: Result<Role | string>;
  createRole: (roleName: string) => void;
  deleteRole: (roleId: string) => void;
  updateRole: (role: Role) => void;
} => {
  const { fetcher, result } = useFetch<Role>();

  const createRole = useCallback(
    (roleName: string) => {
      fetcher(process.env.REACT_APP_SERVER + "/role", { method: "POST", body: JSON.stringify({ name: roleName, active: true }) });
    },
    [fetcher],
  );

  const deleteRole = useCallback(
    (roleId: string) => {
      fetcher(process.env.REACT_APP_SERVER + `/role/${roleId}`, { method: "DELETE" });
    },
    [fetcher],
  );

  const updateRole = useCallback(
    (role: Role) => {
      fetcher(process.env.REACT_APP_SERVER + `/role/${role.id}`, { method: "PUT", body: JSON.stringify({ ...role }) });
    },
    [fetcher],
  );

  return {
    roleResult: result,
    createRole,
    deleteRole,
    updateRole,
  };
};

export const useAllPrivileges = (): {
  allPrivilegesResult: Result<Privilege[]>;
  refreshAllPrivileges: () => void;
} => {
  const { fetcher: privilegesFetcher, result: allPrivilegesResult } = useFetch<Privilege[]>();

  const getPrivileges = useCallback(() => {
    privilegesFetcher(process.env.REACT_APP_SERVER + "/role/privileges");
  }, [privilegesFetcher]);

  useEffect(() => {
    getPrivileges();
  }, [getPrivileges]);

  return {
    allPrivilegesResult: allPrivilegesResult,
    refreshAllPrivileges: getPrivileges,
  };
};
