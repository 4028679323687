import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

interface Notification {
  type: "success" | "error" | "warn" | "info";
  message: string;
}

interface NotificationType {
  notification: Notification | null;
  resetNotification: () => void;
  setNotification: Dispatch<SetStateAction<Notification | null>>;
}
export const NotificationContext = createContext({} as NotificationType);

const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const resetNotification = () => setNotification(null);

  useEffect(() => {
    if (notification === null) return;

    switch (notification.type) {
      case "success":
        toast.success(notification.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        break;
      case "info":
        toast.info(notification.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        break;
      case "warn":
        toast.warn(notification.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        break;
      case "error":

      default:
        toast.error(notification.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
    }
  }, [notification]);

  const data = {
    notification,
    setNotification,
    resetNotification,
  };

  return (
    <NotificationContext.Provider value={data}>
      {children}
      <ToastContainer />
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
