import { Avatar, Box, Card, Container, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useCurrentUser } from "../../clients/userClient";

const ProfileScreen: FC = () => {
  const { userResult } = useCurrentUser();

  return userResult.status === "loaded" ? (
    <Container maxWidth="md" data-testid="profile">
      <Box sx={{ display: "flex", my: 4 }}>
        <Box sx={{ px: 4 }}>
          <Box sx={{ ml: 1 }}>
            <Avatar sx={{ width: 94, height: 94 }} src={userResult.payload.accountInfo.picture} />
          </Box>
          <Typography sx={{ mt: 2 }} variant="h5">
            {userResult.payload.accountInfo.username}
          </Typography>
          <Typography variant="subtitle2">
            <FormattedMessage id="AccountScreen.lastLogin" />: {userResult.payload.accountInfo.lastLogin}
          </Typography>

          <Card sx={{ mt: 2, p: 1 }} style={{ fontSize: 11, fontWeight: 400 }}>
            <Typography sx={{ textTransform: "uppercase" }} variant="overline">
              <FormattedMessage id="AccountScreen.contact" />
            </Typography>
            <Box display="flex" alignItems="center">
              <EmailOutlinedIcon sx={{ fontSize: 16, mr: 1 }} />
              {userResult.payload.accountInfo.email}
            </Box>
          </Card>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="caption" style={{ fontWeight: 600 }}>
            <FormattedMessage id="AccountScreen.roleCaption" />
          </Typography>

          <Card sx={{ mt: 2, p: 1 }}>
            <Box sx={{ mb: 1 }}> </Box>
            {userResult.payload.accountInfo.roles.map((role) => (
              <Box key={role.id} sx={{ mb: 1 }}>
                <Typography component={"span"} style={{ verticalAlign: "middle", display: "inline-flex" }}>
                  <AssignmentIndIcon sx={{ color: "primary.main" }} />
                  <Typography
                    sx={{
                      ml: 2,
                      color: "primary.main",
                    }}>
                    {role.name}
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Card>
        </Box>
      </Box>
    </Container>
  ) : null;
};

export default ProfileScreen;
