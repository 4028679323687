import { FC, useState, useEffect, useContext } from "react";
import {
  Avatar,
  Box,
  Card,
  Chip,
  Container,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Edit, Inbox } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { grey } from "@mui/material/colors";
import { ProjectEmployee } from "../../clients/projectClient";
import { Role } from "../../clients/roleClient";
import { ProjectContext } from "../../contexts/ProjectContext";
import EditProjectEmployeesModal from "./EditProjectEmployeesModal";

const EmployeeListTabPanel: FC = () => {
  const { currentProject } = useContext(ProjectContext);
  const [projectEmployees, setProjectEmployees] = useState<ProjectEmployee[]>(currentProject.projectEmployees);
  const [newProjectEmployeeDialog, setNewProjectEmployeeDialog] = useState<boolean>(false);

  const tableHeaders = [
    "ProjectDetailScreen.tab.employeeList.table.name",
    "ProjectDetailScreen.tab.employeeList.table.activeFromTo",
    "ProjectDetailScreen.tab.employeeList.table.active",
    "ProjectDetailScreen.tab.employeeList.table.roles",
  ];

  const getLocaleString = (date: Date) => {
    return date.toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" });
  };

  useEffect(() => {
    setProjectEmployees(currentProject.projectEmployees);
  }, [currentProject]);

  return (
    <Container>
      <TableContainer component={Card}>
        <Table stickyHeader>
          <TableHead sx={{ p: 0 }}>
            <TableRow>
              {tableHeaders.map((tableHeader) => (
                <TableCell key={tableHeader} sx={{ backgroundColor: grey[300] }} colSpan={tableHeader.includes("name") ? 2 : 1}>
                  <Typography variant="h5">
                    <FormattedMessage id={tableHeader} />
                  </Typography>
                </TableCell>
              ))}
              <TableCell align="right" sx={{ backgroundColor: grey[300], py: 0 }}>
                <LoadingButton
                  data-testid="EditProjectEmployeesButton"
                  loading={newProjectEmployeeDialog}
                  loadingPosition="start"
                  onClick={() => setNewProjectEmployeeDialog(true)}
                  startIcon={<Edit />}
                  variant="contained">
                  <FormattedMessage id="ProjectDetailScreen.tab.employeeList.table.editButton" />
                </LoadingButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectEmployees.length > 0 ? (
              projectEmployees.map((projectEmployee: ProjectEmployee) => (
                <TableRow
                  data-testid={`ProjectEmployeeRow-${projectEmployee.userDto.accountInfo.username}`}
                  hover
                  key={projectEmployee.userDto.id}>
                  <TableCell sx={{ py: 0, pr: 0, width: "44px" }}>
                    <Avatar sx={{ width: "44px", height: "44px" }} src={projectEmployee.userDto.accountInfo.picture} />
                  </TableCell>
                  <TableCell sx={{ py: "10px", width: "200px" }}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="h5">{projectEmployee.userDto.accountInfo.username}</Typography>
                      <Typography variant="body2">{projectEmployee.userDto.accountInfo.email}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: "10px", width: "100px" }}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">{getLocaleString(new Date(projectEmployee.activeFrom as string))}</Typography>
                      <Typography variant="body2">{getLocaleString(new Date(projectEmployee.activeTo as string))}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell size="small" sx={{ py: "10px", width: "75px" }}>
                    <Switch size="small" checked={projectEmployee.active} />
                  </TableCell>
                  <TableCell sx={{ py: "10px" }} colSpan={2}>
                    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                      {projectEmployee.userDto.accountInfo.roles.map((role: Role) => (
                        <Chip size="small" color="primary" key={role.id} label={role.name} />
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow data-testid="EmptyStateRow">
                <TableCell align="center" colSpan={8}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Inbox color="action" sx={{ mb: 2, width: 128, height: 128, p: 3, backgroundColor: "#e5e5e5", borderRadius: "50%" }} />
                    <Typography color="action" variant="subtitle1">
                      <FormattedMessage id="ProjectDetailScreen.tab.employeeList.table.emptyState" />
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <EditProjectEmployeesModal openDialog={newProjectEmployeeDialog} setOpenDialog={setNewProjectEmployeeDialog} />
    </Container>
  );
};

export default EmployeeListTabPanel;
