import { Box, IconButton, Card, Checkbox, Typography, Stack, CircularProgress, FormControlLabel, Grid } from "@mui/material";
import { Edit, Cancel, Save } from "@mui/icons-material";
import { FC, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { User } from "../../../clients/userClient";
import { Role, useAllRoles } from "../../../clients/roleClient";
import ErrorMessage from "../../ErrorMessage";
import { PrivilegeContext } from "../../../contexts/PrivilegeContext";
import { PrivilegeEnum } from "../../RolesScreen/PrivilegeEnum";

interface EmployeeRoleTableProps {
  user: User;
  updateUserRoles: (id: string, userRoles: Role[]) => void;
}

const EmployeeRoleTable: FC<EmployeeRoleTableProps> = ({ user, updateUserRoles }: EmployeeRoleTableProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [roleArray, setRoleArray] = useState<Role[]>([...user.accountInfo.roles]);
  const { checkForPrivilege } = useContext(PrivilegeContext);

  const { allRolesResult } = useAllRoles();

  return (
    <Box sx={{ flexGrow: 1, width: 300 }}>
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection={"row"}>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id="EmployeeProfileScreen.roleCaption" />
            </Typography>
          </Box>
          {checkForPrivilege(PrivilegeEnum.CAN_EDIT_USER) ? (
            !editMode ? (
              <Grid item>
                <IconButton
                  aria-label="roleEditButton"
                  color="default"
                  onClick={() => {
                    setEditMode(!editMode);
                  }}>
                  <Edit />
                </IconButton>
              </Grid>
            ) : editMode ? (
              <Grid item>
                <IconButton
                  aria-label="roleSaveButton"
                  color="default"
                  onClick={() => {
                    updateUserRoles(user.id, roleArray);
                    setEditMode(!editMode);
                  }}>
                  <Save />
                </IconButton>
                <IconButton
                  aria-label="roleCancelButton"
                  color="default"
                  onClick={() => {
                    setRoleArray([...user.accountInfo.roles]);
                    setEditMode(!editMode);
                  }}>
                  <Cancel />
                </IconButton>
              </Grid>
            ) : null
          ) : null}
        </Box>
        <Card sx={{ mt: 2, p: 2 }}>
          {editMode ? (
            <>
              {allRolesResult.status === "loaded" ? (
                <>
                  {allRolesResult.payload.map((role) => (
                    <Box key={role.id} sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-left", alignItems: "center", ml: 0.1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            aria-label={role.id}
                            name={role.id}
                            disabled={role.name === "admin" ? true : false}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const checkedRole = allRolesResult.payload.find((element) => element.id === event.target.name);
                              if (event.target.checked) {
                                if (checkedRole) {
                                  setRoleArray((prevRoleArray) => [...prevRoleArray, checkedRole]);
                                }
                              } else {
                                setRoleArray((prevRoleArray) => prevRoleArray.filter((element) => element.id !== event.target.name));
                              }
                            }}
                            checked={roleArray.find((element) => element.id === role.id) ? true : false}
                          />
                        }
                        label={
                          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Typography
                              sx={{
                                ml: 1,
                                color: "primary.main",
                              }}>
                              {role.name}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  ))}
                </>
              ) : allRolesResult.status === "error" ? (
                <ErrorMessage messageId={allRolesResult.messageId} />
              ) : (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
            </>
          ) : (
            <>
              {roleArray.map((role) => (
                <Grid key={role.id} alignItems="center" container justifyContent="flex-left" sx={{ height: 42 }}>
                  <AssignmentIndIcon sx={{ color: "primary.main" }} />
                  <Typography
                    sx={{
                      ml: 2,
                      color: "primary.main",
                    }}>
                    {role.name}
                  </Typography>
                </Grid>
              ))}
            </>
          )}
        </Card>
      </>
    </Box>
  );
};

export default EmployeeRoleTable;
