import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import theme from "../../assets/theming/theme";
import { LoadingButton } from "@mui/lab";
import { FormattedMessage } from "react-intl";
import { User } from "../../clients/userClient";
import { useUpdateProject } from "../../clients/projectClient";
import { Inbox, Save } from "@mui/icons-material";
import { ProjectContext } from "../../contexts/ProjectContext";
import { UserContext } from "../../contexts/UserContext";

interface EditContactPersonsModalProps {
  openDialog: boolean;
  setOpenDialog: (newContactPersonDialog: boolean) => void;
}

const EditContactPersonsModal: FC<EditContactPersonsModalProps> = ({ openDialog, setOpenDialog }: EditContactPersonsModalProps) => {
  const [search, setSearch] = useState("");
  const [confirmationLoading, setConfirmationLoading] = useState<boolean>(false);
  const [userDisplayList, setUserDisplayList] = useState<User[]>([]); /*                Display    */
  const [selectedContactPersons, setSelectedContactPersons] = useState<User[]>([]); /*  Selection  */
  const { users } = useContext(UserContext); /*                                         Data       */
  const { currentProject, updateContactPersons } = useContext(ProjectContext);
  const { contactPersons } = currentProject;
  const { updateProjectResult } = useUpdateProject(currentProject.id);

  const confirmSelection = () => {
    updateContactPersons(selectedContactPersons);
    setConfirmationLoading(true);
    handleClose();
  };

  const handleClose = useCallback(() => {
    setOpenDialog(false);
    setSelectedContactPersons(contactPersons);
    setConfirmationLoading(false);
    setSearch("");
  }, [setOpenDialog, setSelectedContactPersons, setConfirmationLoading, setSearch, contactPersons]);

  const isUserSelected = (user: User): boolean => {
    return selectedContactPersons.some((selectedUser) => selectedUser.id === user.id);
  };

  const toggleUserSelection = (user: User) => {
    if (isUserSelected(user)) setSelectedContactPersons(selectedContactPersons.filter((selectedUser) => selectedUser.id !== user.id));
    else setSelectedContactPersons([...selectedContactPersons, user]);
  };

  useEffect(() => {
    if (search !== "") setUserDisplayList(users.filter((user) => user.accountInfo.username.toLowerCase().includes(search.toLowerCase())));
    else setUserDisplayList(users);
  }, [search, users]);

  useEffect(() => {
    if (userDisplayList.length <= 0) {
      setUserDisplayList(users);
      setSelectedContactPersons(contactPersons);
    }
  }, [users, setUserDisplayList, setSelectedContactPersons, contactPersons, userDisplayList]);

  useEffect(() => {
    if (updateProjectResult.status === "loaded" || updateProjectResult.status === "error") handleClose();
  }, [updateProjectResult, handleClose]);

  return (
    <Dialog maxWidth="sm" fullWidth open={openDialog} onClose={handleClose} data-testid="EditContactPersonsModal">
      <DialogTitle>
        <FormattedMessage id="ProjectDetailScreen.contactPersonDialog.title" />
      </DialogTitle>
      <DialogContent dividers sx={{ pb: 0 }}>
        <TextField
          autoFocus
          variant="standard"
          label="Name"
          data-testid="SearchInput"
          fullWidth
          autoCapitalize="a"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Box sx={{ pt: 2 }}>
          {userDisplayList.length > 0 ? (
            <Stack spacing={1} sx={{ maxHeight: "300px", overflow: "auto", pb: 1 }}>
              {userDisplayList.map((user) => (
                <Box
                  component={Card}
                  key={user.id}
                  sx={{
                    alignItems: "center",
                    backgroundColor: isUserSelected(user) ? theme.palette.primary.light : "",
                    borderColor: isUserSelected(user) ? theme.palette.primary.main : "",
                    color: isUserSelected(user) ? theme.palette.primary.main : "black",
                    display: "flex",
                    gap: 2,
                    overflow: "visible",
                    px: 1.5,
                    py: 1,
                  }}>
                  <Avatar sx={{ width: "44px", height: "44px" }} src={user.accountInfo.picture} />
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {user.accountInfo.username}
                    </Typography>
                    <Typography variant="body2">{user.accountInfo.email}</Typography>
                  </Box>
                  <Box sx={{ ml: "auto" }}>
                    <Checkbox
                      data-testid={`SelectedCheckbox-${user.accountInfo.username}`}
                      onChange={() => toggleUserSelection(user)}
                      checked={isUserSelected(user)}
                    />
                  </Box>
                </Box>
              ))}
            </Stack>
          ) : (
            <Card sx={{ display: "flex", alignItems: "center", flexDirection: "column", p: 4 }}>
              <Inbox color="action" sx={{ mb: 2, width: 100, height: 100, p: 3, backgroundColor: "#e5e5e5", borderRadius: "50%" }} />
              <FormattedMessage id="ProjectDetailScreen.contactPersonDialog.emptyState" />
            </Card>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          <FormattedMessage id="ProjectDetailScreen.contactPersonDialog.cancelButton" />
        </Button>
        <LoadingButton
          loading={confirmationLoading}
          data-testid="ConfirmUpdateButton"
          onClick={confirmSelection}
          startIcon={<Save />}
          color="primary"
          variant="contained">
          <FormattedMessage id="ProjectDetailScreen.contactPersonDialog.confirmButton" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditContactPersonsModal;
