import { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { GoogleCredentialResponse } from "@react-oauth/google";
import { Result } from "./useFetch";
import { Role } from "./roleClient";

export interface UserData {
  accountInfo: AccountInfoLogin;
  tokens: Tokens;
  isAdmin?: boolean;
}
export interface AccountInfoLogin {
  roles: Role[];
}
interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export const useLogin = (): {
  handleGoogleSuccessResponse: (googleResponse: GoogleCredentialResponse) => void;
  handleGoogleErrorResponse: () => void;
  result: Result<null>;
} => {
  const [, setCookie] = useCookies(["userData"]);

  const [result, setResult] = useState<Result<null>>({
    status: "initial",
  });

  const errorHandling = useCallback((errorCode: number | null, message: string) => {
    setResult({
      status: "error",
      responseErrorCode: errorCode,
      messageId: message,
    });
  }, []);

  const handleGoogleSuccessResponse = useCallback(
    async (googleResponse: GoogleCredentialResponse) => {
      fetch(process.env.REACT_APP_SERVER + "/session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ token: googleResponse.credential }),
      })
        .then((response) => {
          if (!response.ok) {
            errorHandling(response.status, "General.error.server");
            return false;
          }
          return response.json();
        })
        .then((userData: UserData) => {
          if (userData) {
            localStorage.setItem("accountInfo", JSON.stringify(userData.accountInfo));
            localStorage.setItem("isAdmin", JSON.stringify(userData.isAdmin));
            setCookie(
              "userData",
              { tokens: userData.tokens },
              {
                path: "/",
              },
            );

            setResult({
              status: "loaded",
              payload: null,
              responseStatusCode: 200,
            });
          }
        })
        .catch(() => {
          errorHandling(null, "General.error.server");
        });
    },
    [errorHandling, setCookie],
  );

  const handleGoogleErrorResponse = useCallback(async () => {
    setResult({ status: "loading" });
    errorHandling(null, "General.error.server");
  }, [errorHandling]);

  return { handleGoogleSuccessResponse, handleGoogleErrorResponse, result };
};
