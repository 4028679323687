import { useCallback, useEffect } from "react";
import { Role } from "./roleClient";
import { Result, useFetch } from "./useFetch";

export interface User {
  id: string;
  accountInfo: AccountInfo;
  childAllowance?: number;
  city?: string;
  dayOfBirth?: string | null;
  employeeId?: string;
  entryDate?: string | null;
  healthInsurance?: string;
  highestDegree?: string;
  houseNumber?: string;
  iban?: string;
  jobTitle?: string;
  firstName: string;
  nationality?: string;
  partOrFullTime?: string;
  phonePrivate?: string;
  phoneWork?: string;
  probation?: boolean;
  religion?: string;
  resignationDate?: string | null;
  socialSecurityNumber?: string;
  street?: string;
  lastName: string;
  taxClass?: number;
  taxIdentificationNumber?: string;
  taxOffice?: string;
  vacationDays?: string;
  zipCode?: string;
  privateEmail?: string;
}

export interface CreateUser {
  accountInfo: CreateAccountInfo;
  childAllowance?: number | null;
  city?: string;
  dayOfBirth?: string | null;
  employeeId?: string | null;
  entryDate?: string | null;
  healthInsurance?: string;
  highestDegree?: string;
  houseNumber?: string;
  iban?: string | null;
  jobTitle?: string;
  firstName: string;
  nationality?: string;
  partOrFullTime?: string;
  phonePrivate?: string | null;
  phoneWork?: string | null;
  probation?: boolean;
  religion?: string;
  resignationDate?: string | null;
  socialSecurityNumber?: string | null;
  street?: string;
  lastName: string;
  taxClass?: number;
  taxIdentificationNumber?: string | null;
  taxOffice?: string;
  vacationDays?: string;
  zipCode?: string;
  privateEmail?: string;
}

export interface AccountInfo {
  username: string;
  email?: string;
  picture?: string;
  lastLogin?: string;
  roles: Role[];
  isAdmin?: boolean;
}

export interface CreateAccountInfo {
  username: string | null;
  email?: string;
  picture?: string;
  lastLogin?: string;
  roles: string[];
}

export const useAllUsers = (): { usersResult: Result<User[]>; refreshUsers: () => void } => {
  const { fetcher, result } = useFetch<User[]>();

  const getUsers = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + "/user");
  }, [fetcher]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return {
    usersResult: result,
    refreshUsers: getUsers,
  };
};

export const useDeleteUser = (): {
  deleteUserResult: Result<null>;
  deleteUser: (userId: string) => void;
} => {
  const { fetcher, result } = useFetch<null>();
  const deleteUser = useCallback(
    (userId: string) => {
      fetcher(process.env.REACT_APP_SERVER + `/user/${userId}`, { method: "DELETE" });
    },
    [fetcher],
  );
  return {
    deleteUser,
    deleteUserResult: result,
  };
};
export const useCreateUser = (): {
  createUserResult: Result<User>;
  createUser: (user: CreateUser) => void;
} => {
  const { fetcher, result } = useFetch<User>();
  const createUser = useCallback(
    (user: CreateUser) => {
      fetcher(process.env.REACT_APP_SERVER + "/user", { method: "POST", body: JSON.stringify(user) });
    },
    [fetcher],
  );
  return {
    createUser,
    createUserResult: result,
  };
};
export const useUser = (): {
  userResult: Result<User>;
  updateUser: (user: User) => void;
  updateUserRoles: (userId: string, userRoles: Role[]) => void;
  getUser: (id: string) => void;
} => {
  const { fetcher, result } = useFetch<User>();
  const updateUser = useCallback(
    (user) => {
      const roleNames = user.accountInfo.roles.map((role: Role) => {
        return role.name;
      });
      user.accountInfo.roleNames = roleNames;
      fetcher(process.env.REACT_APP_SERVER + `/user/${user.id}`, { method: "PUT", body: JSON.stringify(user) });
    },
    [fetcher],
  );

  const updateUserRoles = useCallback(
    (userId: string, userRoles: Role[]) => {
      fetcher(process.env.REACT_APP_SERVER + `/user/${userId}/roles`, {
        method: "PUT",
        body: JSON.stringify({ roleNames: userRoles.map((role) => role.name) }),
      });
    },
    [fetcher],
  );

  const getUser = useCallback(
    (id: string) => {
      fetcher(process.env.REACT_APP_SERVER + `/user/${id}`);
    },
    [fetcher],
  );

  return {
    userResult: result,
    updateUser,
    updateUserRoles,
    getUser,
  };
};

export const useCurrentUser = (): {
  userResult: Result<User>;
  refreshUser: () => void;
} => {
  const { fetcher, result } = useFetch<User>();

  const getUser = useCallback(() => {
    fetcher(process.env.REACT_APP_SERVER + "/user/profile");
  }, [fetcher]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return {
    refreshUser: getUser,
    userResult: result,
  };
};
