import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import SalaryProvider from "../../../../contexts/SalaryContext";
import EmployeeSalaryTable from "./EmployeeSalaryTable";

const EmployeeSalaryInformation: FC = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: 4,
        overflowX: "auto",
        p: 0,
        textAlign: "center",
        whiteSpace: "nowrap",
      }}>
      <Box alignItems="center" display="flex">
        <Typography variant="h5">
          <FormattedMessage id="EmployeeSalaryInformation.salaryInformation" />
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <SalaryProvider>
          <EmployeeSalaryTable />
        </SalaryProvider>
      </Box>
    </Box>
  );
};

export default EmployeeSalaryInformation;
